<template>
    <div :id="'goto-id-' + idIndex" :data-id="idIndex" @click="itemClicked(gotoId, 'workspaceContainer')">
        <div
            class="item-frame shadow-sm bg-white item-frame-rounded"
            :class="itemFrameClass"
            @mouseover="hovered = true"
            @mouseleave="hovered = false"
        >
            <div
                class="handle-widget frame-header cursor-pointer text-md d-flex justify-content-between align-items-center px-2"
                :class="[{ draggable }, { 'active-item': opened, 'item-frame-rounded': !opened && !hasPreview }]"
                :style="
                    item && item.type === 'word_bank'
                        ? opened
                            ? { backgroundColor: item.data.color + '80' }
                            : { backgroundColor: item.data.color }
                        : {}
                "
                @click.prevent="toggleWidget()"
            >
                <div
                    v-if="!isFirstItem && draggable"
                    v-b-tooltip.hover
                    class="drag-handle d-flex position-absolute align-items-center justify-content-center"
                    title="Reorder"
                    :class="dragHandleClass"
                >
                    <a
                        v-if="gotoId !== 'docHeader'"
                        :id="'handle-' + index"
                        aria-label="drag"
                        style="line-height: 0"
                        @click.prevent=""
                    >
                        <IconEightDots v-if="!addDisabled" />
                    </a>
                </div>
                <span v-if="numberable" class="numbering-button-wrapper position-absolute">
                    <button
                        v-if="numbering && numbering.number > 0"
                        :id="uniqueNumberingMenu"
                        class="btn-numbering d-flex justify-center align-items-center rounded-sm text-sm ml-3 prevent-drag"
                        @click.stop="show = !show"
                    >
                        <NumberFormatIndex
                            v-if="item.numbering.format !== 7"
                            :index-info="item.numbering || defaultOption"
                        />
                        <NumberFormatNoneDisplay v-else />
                    </button>

                    <b-popover :target="uniqueNumberingMenu" :show.sync="show" triggers="click blur">
                        <div v-click-outside="handleClickOutside" class="popover-body-padding-offset">
                            <div class="mb-2">
                                <b-form-checkbox
                                    v-model="numbering_restarted"
                                    switch
                                    :disabled="skip_numbering"
                                    @change="renumberItems"
                                >
                                    Restart at 1
                                </b-form-checkbox>

                                <b-form-checkbox v-model="skip_numbering" switch @change="toggleNumberVisibility">
                                    Skip Numbering
                                </b-form-checkbox>
                            </div>
                            <div>
                                <p class="text-muted f-11 mb-0 mt-3 font-weight-bold">
                                    {{ isMultipleChoiceWidget ? 'NUMBER' : 'QUESTION' }} FORMAT
                                </p>
                                <div class="flex flex-column w-full">
                                    <NestedSelect
                                        :default-option="defaultOption"
                                        :options="numberFormatOptions"
                                        :show-premium="showPremium"
                                        @change="(key) => numberFormatChanged(key)"
                                    />
                                </div>
                                <MultipleChoicePreviewFormat
                                    v-if="isMultipleChoiceWidget"
                                    :item="item"
                                    :index="index"
                                    :show-premium="showPremium"
                                    @click="show = false"
                                />
                                <ChecklistPreviewFormat
                                    v-if="item.type === 'checklist'"
                                    :item="item"
                                    :index="index"
                                    :show-premium="showPremium"
                                    @click="show = false"
                                />
                            </div>
                        </div>
                    </b-popover>
                </span>
                <span
                    class="frame-title font-weight-bold d-flex align-items-center"
                    :class="[{ highlight: opened, pointer: !document.is_published }]"
                >
                    {{ title }}
                    <span
                        v-if="wordbankColor"
                        class="ml-2 p-2 border border-light rounded-circle"
                        :style="{ 'background-color': wordbankColor }"
                    ></span>
                </span>
                <div
                    class="frame-header-action d-flex align-items-center justify-content-center"
                    :class="{ 'px-2': item && itemHidden }"
                    :style="{ width: frameHeaderActionWidth }"
                >
                    <div
                        v-if="showDeleteBtn"
                        v-b-tooltip.hover
                        title="Remove Item"
                        class="w-100 h-100 text-danger text-lg d-flex align-items-center justify-content-center prevent-drag"
                        aria-label="Delete"
                        role="button"
                        @click.stop="deleteItem"
                    >
                        <IconDelete />
                    </div>
                    <IconEyeSlash
                        v-if="item && itemHidden"
                        v-b-tooltip.hover
                        title="Hidden"
                        class="mx-1 text-muted pointer"
                    />
                </div>
            </div>
            <div class="frame-body">
                <div v-if="opened" class="frame-content text-left p-2">
                    <slot></slot>
                </div>
                <div
                    v-if="!addDisabled && !opened"
                    class="pointer text-preview"
                    @click.prevent="toggleWidget(gotoId, 'workspaceContainer')"
                >
                    <div v-if="item">
                        <div
                            v-if="item.data && item.data.subtitle"
                            class="subtitle py-1 pr-3 text-truncate"
                            v-html="item && item.data && item.data.subtitle"
                        ></div>
                        <div
                            v-if="item.data && item.data.instruction"
                            class="subtitle py-1 pr-3 text-truncate"
                            v-html="item && item.data && item.data.instruction"
                        ></div>
                        <div
                            v-if="item.data && item.data.title"
                            class="subtitle py-1 pr-3 text-truncate"
                            v-html="item && item.data && item.data.title"
                        ></div>
                    </div>
                    <div v-else>
                        <div class="subtitle py-1 pr-3 text-truncate" v-html="document.title"></div>
                    </div>
                </div>
            </div>
            <div v-if="opened && !hideOptions" class="frame-footer d-flex justify-content-end align-items-center py-2">
                <span v-if="!hideOptions && item?.type !== 'word_bank'" class="d-flex mr-3" @click="duplicateItem">
                    <IconDuplicate v-b-tooltip.hover title="Duplicate" class="text-muted pointer" />
                </span>
                <b-form-checkbox
                    v-if="!item"
                    v-b-tooltip.hover
                    class="mr-2"
                    title="Hide"
                    @change="handleHide(Number($event))"
                ></b-form-checkbox>

                <div v-else class="mr-3">
                    <span v-if="!itemHidden" class="d-flex" @click="handleHide">
                        <IconEyeSlash v-b-tooltip.hover title="Hide" class="text-muted pointer" />
                    </span>
                    <span v-else class="d-flex" @click="handleHide">
                        <IconEye v-b-tooltip.hover title="Show" class="text-muted pointer" />
                    </span>
                </div>
            </div>
        </div>
        <div
            v-show="opened || showAddWidget"
            v-if="!addDisabled"
            :id="`add_widgets_item_${uniqueRef}`"
            class="position-relative"
            @click.stop=""
        >
            <!-- Close button -->
            <div
                v-if="!opened && showAddWidget"
                class="close-add-item-widget pointer d-flex justify-content-center align-items-center rounded-circle d-none"
                style="z-index: 6"
                @click="showAddWidget = false"
            >
                <IconX />
            </div>
            <!-- Add widget TODO: check if we can remove @added flow, currently it's being done on document store only -->
            <AddItem
                ref="addItem"
                :open-close-main-menu="changeShow()"
                :item-index="index"
                :item="item ? item : null"
                @added="newItemAdded"
                @showFalse="closePopOverOnMenuOpen"
                @addItemDropdownOpened="addItemDropdownOpened"
            />
        </div>

        <div @mouseover="mouseEnter" @mouseleave="mouseLeave" @click.stop="expandAddWidget(uniqueRef)">
            <div
                v-show="!opened && !showAddWidget"
                v-if="!addDisabled"
                :id="`add_widgets_button_${uniqueRef}`"
                class="add-item text-center pointer w-100 my-1"
                style="z-index: 0"
            >
                <div class="plus">
                    <IconButton icon="Plus" size="tiny" variant="success" @click="expandAddWidget(uniqueRef)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import uniqueId from 'lodash.uniqueid'
import { mapGetters, mapState } from 'vuex'
import Activity from '../../store/models/Activity'
import { numberingFormats } from '../../objects/Document'
import EditPayWall from '../../mixins/EditPayWall'
import AddItem from './AddItem.vue'
import IconEightDots from '../../stories/components/icons/IconEightDots.vue'
import IconEyeSlash from '../../stories/components/icons/IconEyeSlash.vue'
import IconEye from '../../stories/components/icons/IconEye.vue'
import IconDuplicate from '../../stories/components/icons/IconDuplicate.vue'
import IconDelete from '../../stories/components/icons/IconDelete.vue'
import IconX from '../../stories/components/icons/IconX.vue'
import IconButton from '../../stories/components/buttons/IconButton.vue'
import NumberFormatIndex from '../../components/format-helpers/NumberFormatIndex.vue'
import NestedSelect from '../../stories/components/form/NestedSelect.vue'
import { find } from 'lodash'
import ClickOutside from '../../directives/click-outside'
import NumberFormatNoneDisplay from './components/NumberFormatNoneDisplay.vue'
import { WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM } from '../../store/helpers/documentHelpers'
import WithAddItem from '../../mixins/WithAddItem'

export default defineComponent({
    name: 'ItemFrame',
    components: {
        NumberFormatNoneDisplay,
        AddItem,
        IconEightDots,
        IconEyeSlash,
        IconEye,
        IconDuplicate,
        IconDelete,
        IconX,
        IconButton,
        ChecklistPreviewFormat: () => import('./components/ChecklistPreviewFormat.vue'),
        MultipleChoicePreviewFormat: () => import('./components/MultipleChoicePreviewFormat.vue'),
        NestedSelect,
        NumberFormatIndex,
    },
    directives: {
        'click-outside': ClickOutside,
    },
    mixins: [EditPayWall, WithAddItem],
    props: {
        title: {
            type: String,
            default: '',
        },
        gotoId: {
            type: String,
            default: '',
        },
        editable: Boolean,
        removeable: Boolean,
        visible: Boolean,
        opened: Boolean,
        hideOptions: Boolean,
        draggable: Boolean,
        index: {
            type: Number,
            default: -1,
        },
        data: {
            type: Object,
            default: () => {},
            WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
        },
        item: Activity,
        addDisabled: Boolean,
        isFirstItem: {
            type: Boolean,
            default: false,
        },
        isScrolling: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uniqueNumberingMenu: null,
            numberFormatOptions: numberingFormats,
            showAddWidget: false,
            numbering_restarted: false,
            skip_numbering: false,
            show: false,
            hovered: false,
            selected: '',
        }
    },
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            instructions: 'document/documentInstructions',
            isWorksheet: 'document/isWorksheet',
            isLoggedIn: 'user/isLoggedIn',
            isScrollingSideBar: 'document/isScrollingSideBar',
            currentWidget: 'document/currentWidget',
            documentItems: 'document/documentItems',
        }),
        uniqueRef() {
            return this.item?.id || `header`
        },
        itemFrameClass() {
            return {
                'outline-primary-left': this.opened,
                'mt-2': !this.isFirstItem && !this.item,
            }
        },
        numbering() {
            return this.item?.numbering
        },
        wordbankColor() {
            return this.item?.getWordBankColor()
        },
        numberable() {
            return this.item?.numberable
        },
        subtitle() {
            return this.item?.getSubtitle()
        },
        idIndex() {
            return this.index === undefined || !this.item ? 'header' : this.item.id
        },
        defaultOption() {
            if (!this.item || !this.item.numbering)
                return {
                    number: 1,
                    format: 2,
                }

            for (const option of this.numberFormatOptions) {
                if (option.value === this.item.numbering.format) return option

                if (option.subOptions) {
                    const subOption = find(
                        option.subOptions,
                        (subOption) => parseInt(subOption.value) === this.item.numbering.format,
                    )
                    if (subOption) return subOption
                }
            }

            return {
                label: '1.',
                value: '2',
                isPremium: false,
            }
        },
        itemHidden: {
            get() {
                return this.item?.hide
            },
            async set(value) {
                await this.$store.dispatch('document/toggleItemVisibility', { index: this.index, hide: value })
                if (!this.numberable) return

                await this.renumberItems()
            },
        },
        showDeleteBtn() {
            return this.removeable && (this.opened || this.hovered)
        },
        dragHandleClass() {
            return {
                'bg-secondary': !this.opened,
                'ml-1': this.opened,
                'no-preview': !this.opened && !this.hasPreview,
            }
        },
        hasPreview() {
            return (
                (this.isWorksheet && this.isFirstItem) ||
                (this.item &&
                    this.item.data &&
                    (this.item.data.subtitle || this.item.data.instruction || this.item.data.title))
            )
        },
        showPremium() {
            return this.isLoggedIn && !this.hasFeature('formatting', this.document, this.document.entity_type)
        },
        frameHeaderActionWidth() {
            return this.item && this.itemHidden ? (this.showDeleteBtn ? `5.5rem` : `2.8rem`) : `3rem`
        },
        isMultipleChoiceWidget() {
            return (
                this.item?.type === 'multiple_choice' ||
                (this.item?.type === 'word_scramble' && this.item?.data?.type === WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM)
            )
        },
    },
    watch: {
        isScrollingSideBar: {
            handler() {
                this.$nextTick(async () => {
                    this.handleClickOutside()
                    await this.$store.dispatch('document/setIsScrollingSideBar', false)
                })
            },
            immediate: true,
        },
        opened: {
            async handler(newValue) {
                if (newValue) {
                    await this.openAddWidget(this.uniqueRef)
                }
            },
            immediate: true,
        },
    },
    created() {
        this.uniqueNumberingMenu = uniqueId('numbering_menu_')
        this.numbering_restarted = this.item?.numbering?.numbering_restarted
        this.skip_numbering = this.item?.numbering?.hidden
        this.setNumberingFormat(this.item?.numbering?.format || 0)
    },
    mounted() {
        this.hideAllAddWidgetButtons()
    },
    methods: {
        close() {
            this.show = false
        },
        handleClickOutside() {
            this.close()
        },
        numberFormatChanged(key) {
            this.setNumberingFormat(key)
            this.close()
        },
        async openAddWidget(uniqueRef = null) {
            if (!uniqueRef) uniqueRef = this.uniqueRef

            await this.hideAddItemsAndButtons(uniqueRef)

            if (this.currentWidget.focusedItem) {
                await this.showAddWidgetByUniqueRef(this.currentWidget.focusedItem.id)
            } else if (this.currentWidget.openHeader) {
                await this.showAddWidgetByUniqueRef(uniqueRef)
            } else {
                await this.showLastAddItemWidget()
            }
        },
        changeShow() {
            if (this.show === true) {
                return this.show
            }
        },
        closePopOverOnMenuOpen(e) {
            this.show = e[0]
        },
        async toggleNumberVisibility() {
            this.numbering.hidden = !this.numbering.hidden
            if (this.numbering.hidden) {
                if (this.numbering_restarted) {
                    this.$emit('on-restart-next')
                }
                this.numbering_restarted = false
                this.numbering.numbering_restarted = false
            }
            await this.$store.dispatch('document/renumberItems')
        },
        async renumberItems() {
            if (!this.numberable || typeof this.numbering === 'undefined') return

            this.numbering.numbering_restarted = !this.numbering.numbering_restarted

            if (this.index === 0) {
                return
            }

            let previousItemWithNumberingFound = false

            // Backwards traverse the document items to find the previous one with numbering
            for (let i = this.index - 1; i >= 0; i--) {
                const previousItem = this.documentItems[i]
                if (previousItem.numbering && previousItem.numbering.format) {
                    await this.setNumberingFormat(previousItem.numbering.format)
                    previousItemWithNumberingFound = true
                    break
                }
            }

            if (!previousItemWithNumberingFound) {
                return
            }

            await this.$store.dispatch('document/renumberItems')
        },
        async setNumberingFormat(format) {
            await this.$store.dispatch('document/setItemsFormatAt', {
                at: this.index,
                format: format,
            })
            await this.$store.dispatch('document/storeDocumentState')
        },
        showPopOverAndHideMainMenu() {
            this.show = !this.show
        },
        async handleHide(value = null) {
            if (this.item) {
                this.itemHidden = !this.itemHidden
                return
            }

            this.$store.state.document.title_visible = !value
            this.$store.state.document.student_info_visible = !value
            await this.$store.dispatch('document/renumberItems')
        },
        goto(refName, containerRef) {
            this.$emit('goto', { ref: refName, container: containerRef })
        },
        async toggleWidget() {
            await this.openAddWidget()
            this.$emit('on-edit')
        },
        addItemDropdownOpened(value) {
            this.$emit('addItemDropdownOpened', value)
        },
        newItemAdded(payload = null) {
            this.showAddWidget = false
            this.hideSelf(payload)
        },
        hideSelf(itemAdded) {
            this.hovered = false
            if (itemAdded) {
                this.$nextTick(async () => {
                    await this.showNew(itemAdded)
                })
            }
        },
        async showNew(itemAdded) {
            await this.openAddWidget(itemAdded.id)
        },
        async itemClicked(gotoId, elementClass) {
            this.goto(gotoId, elementClass)
            await this.openAddWidget()
        },
        mouseEnter() {
            let current = this.getWidgetItems(this.uniqueRef)
            if (this.uniqueRef != null) {
                if (current.widget && current.newwidget) {
                    if (current.widget.style.display === 'none') {
                        current.newwidget.style.display = 'block'
                        current.newwidget.style.visibility = 'visible'
                    }
                }
            }
        },
        mouseLeave() {
            let current = this.getWidgetItems(this.uniqueRef)
            if (this.uniqueRef != null) {
                if (current.newwidget) {
                    current.newwidget.style.visibility = 'hidden'
                }
            }
        },
        duplicateItem() {
            this.$emit('on-duplicate')
            this.hovered = false
        },
        async expandAddWidget(uniqueRef = null) {
            if (!uniqueRef) uniqueRef = this.uniqueRef
            await this.hideAddItemsAndButtons(uniqueRef)
            await this.showAddWidgetByUniqueRef(uniqueRef)
            this.$refs.addItem?.openAddItemModal()
        },
        deleteItem() {
            let ref
            const { widget } = this.getWidgetItems(this.uniqueRef)
            if (widget.style.display !== 'none') {
                ref = this.uniqueRef
            }
            this.$emit('on-remove', { ref })
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.item-frame {
    text-align: center;
    background-color: white;
    position: relative;
    width: 100%;

    .frame-header {
        position: relative;
        background-color: $gray-300;
        color: $gray-900;
        padding: 8px 0;
        border-radius: 0.37rem 0.37rem 0 0;
        min-height: 44px;

        &:hover {
            // background-color: #F4F2FF;
            color: $primary;
        }

        .numbering-button-wrapper {
            left: $drag-handle-width;
        }

        .btn-numbering {
            width: 2rem;
            height: 1.75rem;
            line-height: 1;
        }

        .btn-option {
            position: absolute;
            top: 17px;
            left: 10px;
            color: $gray-900;
            font-size: 16px;
        }

        .frame-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .btn-edit {
            position: absolute;
            top: 50%;
            right: 10px;
            color: $gray-900;
            transform: translateY(-50%);
            cursor: pointer;
        }

        &.highlight {
            font-size: 18px;
            color: black;
        }

        &.draggable {
            cursor: move;
        }

        .drag-handle {
            top: 0;
            left: 0;
            height: 100%;
            width: $drag-handle-width;
            border-radius: 0.35rem 0 0 0;
        }

        .no-preview {
            border-radius: 0.35rem 0 0 0.35rem;
        }

        .frame-header-action {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
        }
    }

    .frame-body {
        .subtitle {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            text-align: left;
        }

        .subtitle > p {
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-align: left;
        }

        .frame-content {
            padding: 0 8px;
        }

        .text-preview {
            background-color: $gray-100;
            border-radius: 0 0 0.35rem 0.35rem;
            font-size: 1em;
            padding-left: $drag-handle-width;
        }
    }

    .frame-footer {
        border-top: 1px solid $gray-400;
        padding: 0;

        .remove-option {
            border-left: 1px solid $gray-400;
        }
    }

    .btn-expand {
        position: relative;
        padding: 2px 0;
        z-index: 1;

        .btn-circle {
            width: 24px;
            height: 24px;
            padding: 2px 0;
            border-radius: 50%;
            text-align: center;
            font-size: 13px;
            background-color: $gray-500;
            color: white;
            border: none;
        }

        &:before {
            content: ' ';
            width: 40%;
            height: 1px;
            background-color: $gray-500;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }

    &.outline-primary-left {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            background-color: $primary;
            border-radius: 0.35rem 0 0 0.35rem;
            z-index: 1;
        }
    }
}

.item-frame-rounded {
    border-radius: 0.37rem 0.37rem 0.37rem 0.37rem !important;
}

.menu_item {
    cursor: pointer;
}

.add-item {
    position: relative;
    color: $gray-500;

    &:is(:hover) {
        color: $primary;
    }

    .plus {
        display: flex;
        justify-content: center;
        align-items: center;

        &:before,
        &:after {
            content: '';
            width: 100px;
            height: 2px;
            background-color: $gray-500;
            z-index: -1;
        }

        &:before {
            margin-right: 10px;
        }

        &:after {
            margin-left: 10px;
        }

        &:hover {
            &:before,
            &:after {
                background-color: $primary;
            }
        }
    }
}

.close-add-item-widget {
    position: absolute;
    right: 10px;
    top: -8px;
    color: #c9c9c9;
    background: $gray-900;
    opacity: 1;
    height: 22px;
    width: 22px;
    z-index: 10;

    &:hover {
        color: red;
        opacity: 1;
    }
}

.w-full {
    width: 100%;
}

.custom-container {
    position: relative;
    bottom: -75px;
    left: 10px;
    display: flex;
    width: 150px;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.custom-option {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
}

.custom-option:hover {
    background-color: #e2e2e2; /* Equivalent to hover:bg-gray-200 in Tailwind */
}

.custom-option-top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.custom-option-bottom {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.popover-body-padding-offset {
    padding: 0.5rem 0.75rem;
    margin: -0.5rem -0.75rem;
}
</style>
