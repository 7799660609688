<template>
    <div>
        <Draggable
            v-model="options"
            v-bind="{ handle: '.multi-option-handle', animation: 250 }"
            :disabled="disabled"
            @end="handleDragEnd"
        >
            <div v-for="(option, index) in options" :key="index" :class="{ 'sub-options-wrapper p-2 mb-3': hasSubOptions }">
                <div class="d-flex align-items-center mb-2 justify-between">
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-button
                                class="multi-option-handle"
                                size="sm"
                                variant="secondary"
                                tabindex="-1"
                                :disabled="disabled"
                            >
                                <b-icon icon="grip-horizontal"></b-icon>
                            </b-button>
                        </b-input-group-prepend>

                        <b-form-input
                            :id="parentIndex + '-choice-item-' + index"
                            :ref="parentIndex + '-choice-item-' + index"
                            :placeholder="isListItems ? `Item ${parseInt(index) + 1}` : 'Option'"
                            size="sm"
                            :value="variant === 'word-scramble' ? option : option.answer"
                            :class="{ 'restore-border-radius': hasSingleOption }"
                            :disabled="disabled"
                            @input="updateOption(index, $event)"
                            @keydown.enter="handleEnterKey(index, $event)"
                        ></b-form-input>
                        <b-input-group-append>
                            <DeleteButton
                                :class="deleteButtonClass"
                                :disabled="disabled"
                                radius="right"
                                @click="removeOption(index)"
                            />
                        </b-input-group-append>
                    </b-input-group>
                    <span class="d-flex ml-2">
                        <b-form-checkbox
                            v-if="includeAnswerCheck"
                            :id="`correct-checkbox-${index}`"
                            :key="`option-${index}`"
                            v-model="option.correct"
                            name="options"
                            class="f-14 text-muted ml-1 mr-n2"
                            :class="{ 'restore-border-radius': hasSingleOption }"
                            tabindex="-1"
                            switch
                            :value-field="option.correct"
                            :disabled="disabled"
                            @change="updateData"
                        ></b-form-checkbox>
                        <b-button
                            v-else-if="variant === 'word-scramble'"
                            v-b-tooltip.hover
                            variant="primary"
                            size="sm"
                            tabindex="-1"
                            title="Re-scramble"
                            :style="{
                                opacity: option === '' && hasSingleOption ? 0 : 1,
                                pointerEvents: option === '' && hasSingleOption ? 'none' : 'auto',
                            }"
                            @click="$emit('scramble', index)"
                        >
                            <b-icon size="sm" icon="arrow-repeat"></b-icon>
                        </b-button>
                    </span>
                </div>
                <div v-if="hasSubOptions" class="d-flex flex-column ml-5">
                    <div class="f-11 text-muted d-flex justify-content-between font-weight-bold">
                        <label for="word-scramble-multi-options">Answer Options</label>
                        <span>Correct?</span>
                    </div>
                    <Draggable
                        v-model="internalSubOptionsState"
                        v-bind="{ handle: '.multi-option-handle', animation: 250 }"
                        :disabled="disabled"
                        @end="handleSubDragEnd"
                    >
                        <div v-for="(subOption, subOptionIndex) in subOptions" :key="subOptionIndex">
                            <template v-if="subOption.term_index === index">
                                <div class="d-flex align-items-center mb-2 justify-between">
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-button
                                                class="multi-option-handle"
                                                size="sm"
                                                variant="secondary"
                                                tabindex="-1"
                                                :disabled="disabled"
                                            >
                                                <b-icon icon="grip-horizontal"></b-icon>
                                            </b-button>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            :id="parentIndex + '-choice-item-sub' + subOptionIndex"
                                            :ref="parentIndex + '-choice-item-sub' + subOptionIndex"
                                            :placeholder="
                                                isListItems ? `Item ${parseInt(subOption.sub_index) + 1}` : 'Option'
                                            "
                                            size="sm"
                                            :value="subOption.answer"
                                            :class="{ 'restore-border-radius': hasSingleSubOption(index) }"
                                            :disabled="disabled"
                                            @input="updateSubOption(subOptionIndex, $event)"
                                            @keydown.enter="handleSubOptionEnterKey(subOptionIndex, index, $event)"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <DeleteButton
                                                :class="
                                                    hasSingleSubOption(index)
                                                        ? 'delete-button-hidden'
                                                        : 'delete-button-visible'
                                                "
                                                :disabled="disabled"
                                                radius="right"
                                                @click="removeSubOption(subOptionIndex)"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <span class="d-flex ml-2">
                                        <b-form-checkbox
                                            :id="`correct-checkbox-sub${subOptionIndex}`"
                                            :key="`suboption-${subOptionIndex}`"
                                            v-model="subOption.correct"
                                            tabindex="-1"
                                            name="options"
                                            class="f-14 text-muted ml-1 mr-n2 ml-2"
                                            switch
                                            :value-field="subOption.correct"
                                            :disabled="disabled"
                                            @change="updateSubData(null)"
                                        ></b-form-checkbox>
                                    </span>
                                </div>
                            </template>
                        </div>
                    </Draggable>
                    <div class="d-flex mb-2">
                        <b-input
                            ref="add-new-multi-option"
                            v-model="newMultiSubOption[index]"
                            :class="{ 'new-word-scramble': variant === 'word-scramble' }"
                            type="text"
                            :style="{ width: subOptionWidth, marginLeft: marginLeftDefault }"
                            size="sm"
                            list="add-new-multi-option"
                            :placeholder="newWordPlaceholder"
                            :disabled="disabled"
                            @input="addSubOption(index, $event)"
                        />
                    </div>
                </div>
            </div>
        </Draggable>
        <div class="d-flex my-2">
            <b-input
                ref="add-new-multi-option"
                v-model="newMultiOption"
                :class="{ 'new-word-scramble': variant === 'word-scramble' }"
                type="text"
                :style="{ width: optionWidth, marginLeft: hasSubOptions ? '2.71rem' : marginLeftDefault }"
                size="sm"
                list="add-new-multi-option"
                :placeholder="newWordPlaceholder"
                :disabled="disabled"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import DeleteButton from './buttons/DeleteButton.vue'

export default defineComponent({
    name: 'MultiOptions',
    components: {
        DeleteButton,
        Draggable,
    },
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        focusIndex: {
            type: Number,
            default: -1,
        },
        hasAnswer: {
            type: Boolean,
            default: true,
        },
        isListItems: {
            type: Boolean,
            default: false,
        },
        parentIndex: {
            type: Number,
            default: -1,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'multiple-choice',
        },
        focus: {
            type: Boolean,
            default: false,
        },
        hasSubOptions: {
            type: Boolean,
            default: false,
        },
        subOptionsChoices: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            newMultiOption: '',
            options: [],
            optionWidth: '100%',
            subOptionWidth: '168.5px',
            marginLeftDefault: '2.125rem',
            newMultiSubOption: [],
            internalSubOptionsState: this.subOptionsChoices,
        }
    },
    computed: {
        hasSingleOption: function () {
            return this.options.length === 1
        },
        includeAnswerCheck: function () {
            if (this.variant === 'word-scramble') return false

            return this.hasAnswer ?? true
        },
        newWordPlaceholder: function () {
            if (this.variant === 'word-scramble') return '+Add new word'
            return this.isListItems ? 'Add Item' : 'Add Option'
        },
        deleteButtonClass: function () {
            return this.hasSingleOption ? 'delete-button-hidden' : 'delete-button-visible'
        },
        subOptions: {
            get() {
                return this.subOptionsChoices
            },
            set(value) {
                this.subOptions = value
            },
        },
    },
    watch: {
        options: {
            handler() {
                if (this.variant === 'word-scramble') return
                this.$emit('input', this.options)
                this.resizeOptionsWidth()
            },
            deep: true,
        },
        newMultiOption(val) {
            if (val.trim()) {
                this.addOption(val.trim())
            }
        },
        focusIndex: {
            immediate: true,
            handler(val) {
                if (val !== null && document.getElementById(this.parentIndex + `-choice-item-${val}`)) {
                    this.focusItem(val)
                }

                this.resizeOptionsWidth()
            },
        },
        hasSubOptions(val) {
            if (!val) {
                this.resizeOptionsWidth()
                return
            }

            this.options.forEach((_, index) => {
                this.newMultiSubOption[index] = ''
                this.$nextTick(() => {
                    this.addSubOption(index, '', true)
                })
            })

            this.resizeOptionsWidth()
        },
    },
    mounted() {
        this.options = this.value
        this.$nextTick(() => {
            const firstInput = this.$refs[this.parentIndex + '-choice-item-0'][0].$el
            if (firstInput) {
                this.resizeOptionsWidth()
            }

            if (this.focus && firstInput) {
                firstInput.focus()
            }

            if (this.focusIndex !== null) {
                this.focusItem(this.focusIndex)
            }
        })
    },
    methods: {
        updateOption(index, value) {
            if (this.variant === 'word-scramble') {
                this.$nextTick(() => {
                    this.options[index] = value
                    this.updateData()
                })
                return
            }

            this.options[index].answer = value
            this.updateData()
        },
        removeOption(index) {
            this.$nextTick(() => {
                this.options.splice(index, 1)
                const newSubOptions = this.subOptions.filter((subOption) => {
                    if (subOption.term_index === index) return false

                    if (subOption.term_index > index) {
                        subOption.term_index = subOption.term_index - index
                    }
                    return true
                })

                this.updateSubData(newSubOptions)
                this.resizeOptionsWidth()
                this.updateData()
            })
        },
        removeSubOption(subOptionIndex) {
            this.subOptions.splice(subOptionIndex, 1)
            this.resizeOptionsWidth()
            this.updateSubData()
        },
        addOption(val = '') {
            if (this.variant === 'word-scramble') {
                this.options.push(val)

                if (this.hasSubOptions) {
                    this.addSubOption(this.options.length - 1, '', true)
                }

                this.updateData()
                this.$nextTick(() => {
                    let index = this.options.length - 1
                    this.$refs[this.parentIndex + '-choice-item-' + index][0].$el?.focus()
                    this.newMultiOption = ''
                })

                this.resizeOptionsWidth()
                this.updateData()
                return
            }

            this.options.push({
                answer: val,
                correct: false,
            })

            this.resizeOptionsWidth()
            this.updateData()

            this.$nextTick(() => {
                let index = this.options.length - 1
                this.$refs[this.parentIndex + '-choice-item-' + index][0].$el?.focus()
                this.newMultiOption = ''
            })
        },
        addSubOption(index, val = '', isNew = false) {
            const subOptionsFromIndex = this.getSubOptionsFromIndex(index)
            let subIndex = subOptionsFromIndex.length

            if (isNew) {
                this.newMultiSubOption[index] = ''
                this.subOptions.push(
                    {
                        sub_index: subIndex++,
                        term_index: index,
                        answer: '',
                        correct: false,
                    },
                    {
                        sub_index: subIndex++,
                        term_index: index,
                        answer: '',
                        correct: false,
                    },
                )

                this.updateSubData()
                return
            }

            this.subOptions.push({
                sub_index: subOptionsFromIndex.length,
                term_index: index,
                answer: val,
                correct: false,
            })

            this.$nextTick(() => {
                this.newMultiSubOption[index] = ''
                const subOptionElement = document.getElementById(
                    `${this.parentIndex}-choice-item-sub${this.subOptions.length - 1}`,
                )
                subOptionElement.focus()
                this.$forceUpdate()
            })

            this.resizeOptionsWidth()
            this.updateSubData()
        },
        focusItem(val) {
            document.getElementById(this.parentIndex + `-choice-item-${val}`)?.focus()
            this.$emit('clearFocusIndex')
        },
        focusSubItem(val) {
            document.getElementById(this.parentIndex + `-choice-item-sub${val}`)?.focus()
            this.$emit('clearFocusIndex')
        },
        handleDragEnd(event) {
            if (this.variant !== 'word-scramble') return
            const oldIndex = this.subOptions.filter((subOption) => subOption.term_index === event.oldIndex)
            const newIndex = this.subOptions.filter((subOption) => subOption.term_index === event.newIndex)

            oldIndex.forEach((subOption) => {
                subOption.term_index = event.newIndex
            })

            newIndex.forEach((subOption) => {
                subOption.term_index = event.oldIndex
            })

            this.updateData()
            this.updateSubData()
        },
        handleSubDragEnd(event) {
            const { oldIndex, newIndex } = event

            if (oldIndex === newIndex) return

            const movedItem = this.subOptions.splice(oldIndex, 1)[0]

            this.subOptions.splice(newIndex, 0, movedItem)

            this.subOptions.forEach((item, index) => {
                item.sub_index = index
            })

            this.updateSubData()
        },
        handleEnterKey(index, event) {
            if (event.ctrlKey && event.key === 'Enter') return

            if (index < this.options.length - 1) {
                this.focusItem(index + 1)
                return
            }

            this.addOption('')
        },
        handleSubOptionEnterKey(subIndex, index, event) {
            if (event.ctrlKey && event.key === 'Enter') return

            const subOptionsFromIndex = this.getSubOptionsFromIndex(index)

            if (this.subOptions[subIndex].sub_index < subOptionsFromIndex.length - 1) {
                this.focusSubItem(subIndex + 1)
                return
            }

            this.addSubOption(index)
            this.updateSubData()
        },
        updateSubOption(subIndex, value) {
            const subOption = this.subOptions[subIndex]
            subOption.answer = value
            this.newMultiSubOption[subOption.term_index] = ''

            this.updateSubData()
        },
        getSubOptionsFromIndex(index) {
            const subOptionsFromIndex = this.subOptions.filter((subOption) => subOption.term_index === index)
            subOptionsFromIndex.forEach((subOption, subOptionIndex) => {
                if (subOption.term_index !== index) return

                subOption.sub_index = subOptionIndex
            })

            return subOptionsFromIndex
        },
        hasSingleSubOption(index) {
            const subOptions = this.getSubOptionsFromIndex(index)
            return !(subOptions.length > 1)
        },
        updateData() {
            this.$emit('input', this.options)
        },
        updateSubData(value = null) {
            this.$emit('subOptions', value ? value : this.subOptions)
        },
        resizeOptionsWidth() {
            this.$nextTick(() => {
                const firstInput = this.$refs[this.parentIndex + '-choice-item-0'][0].$el
                if (firstInput) {
                    this.optionWidth = firstInput.getBoundingClientRect().width + 'px'
                }
            })
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.new-word-scramble {
    font-style: italic;
}

.delete-button-hidden {
    opacity: 0;
    pointer-events: none;
    cursor: default;
    padding: 0.25rem 0.5rem;
}
.delete-button-visible {
    opacity: 1;
    padding: 0.25rem 0.5rem;
}

.sub-options-wrapper {
    background: #f7f7f7;
    outline: 1px solid #e2e2e2;
    border-radius: 0.25rem !important;
}

.restore-border-radius {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
}
</style>
