import Chance from 'chance'
import StatsigEvent from '../../../common/StatsigEvent'
import UserEvent from '../../../objects/UserEvent'
import DocumentApi from '../../../apis/DocumentApi'
import { copyObject } from '../../../helpers/copyObject'
import { filenameFromTitle } from '../../../common/helpers'

import {
    bingoDefaultTitleOption,
    default_color,
    default_font,
    defaultDocumentItem,
    documentFillable,
    initialize,
    mainDocumentTypes,
    STANDARD_CURSIVE,
    STANDARD_PRINT,
    UPDATE_INTERVAL,
    worksheetDefaultTitleOption,
} from '../../helpers/documentHelpers'
import {
    bingo_types,
    border_styles,
    flashcard_types,
    subjects,
    worksheet_activities_types,
    worksheet_types,
} from '../../../objects/Document'
import Activity from '../../models/Activity'
import kebabCase from 'lodash.kebabcase'
import { mergeDeep } from '../../../helpers/mergeDeep'
import BaseModule from '../base'
import BingoModule from './bingo'
import ImageModule from './image'
import FlashcardModule from './flashcard'
import PremiumModule from '../reusable/premium'
import WorksheetModule from './worksheet'
import ZoomModule from '../reusable/zoom'
import { capitalizeFirstLetter, findSimiliarWord } from '../../../helpers/stringUtils'
import apiClient from '../../../apis/apiClient'
import { dangerToast, successToast } from '../../../helpers/toasts'
import EventApi from '../../../apis/EventApi'
import wmAlert from '../../../helpers/wmAlerts'

import uuidv1 from 'uuid/v1'
import Vue from 'vue'
import { getEntityStatsigValue } from '../../../mixins/StatsigHelper'
import { cloneDeep } from 'lodash'
import ImageUploadApi from '../../../apis/ImageUploadApi'

const WINDOW_MEDIUM_SIZE = 1298

const checkIfTitleWasSet = () => {
    return typeof window.doc !== 'undefined' && window.doc.title && window.doc.title.trim() !== ''
}

export default class DocumentModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            ...initialize,
            vue: null,
            $bvModal: null,
            images: [],
            documents: [],
            filenameEdited: false,
            is_publishing: false,
            last_saved_at: false,
            is_loading: false,
            save_document: false,
            saving: false,
            show_answer_key: 1,
            subjects,
            updateInterval: undefined,
            hasModification: false,
            user_id: undefined,
            user_has_purchased_document: false,
            currentWidget: {
                openHeader: true,
                openInstructions: false,
                pageSettings: false,
                wordsDefinition: false,
                bingoCardSetup: false,
                openBingoWords: false,
                bingoCallList: false,
                focusedItem: undefined,
                openStudentInfo: false,
            },
            selected: null,
            onboarding: false,
            onpublishing: false,
            flashcardHeaderHeight: 0,
            flashcardHeightEmptyPage: 9.9,
            flashcardHeight: 9.6,
            single_purchase_edits: 0,
            first_published_at: null,
            goPremium: false,
            filenameMaxLength: 80,
            show_image_uploader: false,
            is_inline_image: false,
            inline_images_upload: {
                item_index: null,
                input_index: null,
                column: 'term',
            },
            saveError: false,
            mainDocument: '',
            isScrollingSideBar: false,
            isImageUploaderOpen: false,
            titleUpdated: false,
        }
    }

    getters() {
        return {
            ...super.getters(),
            selected: (state) => state.selected,
            onboarding: (state) => state.onboarding,
            onpublishing: (state) => state.onpublishing,
            currentWidget: (state) => state.currentWidget,
            apiCallFeedback: (state) => state.error,
            worksheet: (state) => state.worksheet,
            bingo: (state) => state.bingo,
            flashcard: (state) => state.flashcard,
            entityType: (state) => state.entity_type,
            isBingo: (state) => state.entity_type === 'bingo',
            isFlashcard: (state) => state.entity_type === 'flashcard',
            isWorksheet: (state) => state.entity_type === 'worksheet',
            documentTitle: (state) => state.title,
            omniStudent: (state) => state.omni_student,
            lastSavedAt: (state) => state.last_saved_at,
            userHasPurchasedDocument: (state) => state.user_has_purchased_document,
            documentEntity: (state, getters) => {
                if (getters.isWorksheet) return state.worksheet
                else if (getters.isBingo) return state.bingo
                else return state?.flashcard
            },
            documents: (state) => state.documents,
            documentModel: (state, getters) => {
                let document = {}
                // populate our document
                documentFillable.forEach((key) => {
                    document[key] = state[key]
                })
                document.grade_levels = state.grade_levels
                document.student_fields = state.student_fields
                document.fake_answers = state.fake_answers

                if (getters.isWorksheet) {
                    document.entity = state.worksheet
                } else if (getters.isBingo) {
                    document.entity = state.bingo
                } else {
                    document.entity = state.flashcard
                }

                if (!document.filename) {
                    if (document.title && document.title.replace(/(<([^>]+)>)/gi, '') !== getters.defaultDocumentTitle) {
                        const content = state.filenameEdited ? document.filename : document.title
                        document.filename = filenameFromTitle(content, document.entity_type).substring(
                            0,
                            state.filenameMaxLength,
                        )
                    } else {
                        const content = state.filenameEdited ? document.filename : ``
                        document.filename = Boolean(content)
                            ? filenameFromTitle(content, document.entity_type).substring(0, state.filenameMaxLength)
                            : ''
                    }
                }

                return document
            },
            storedDocument: (state, getters) => {
                const store_key = getters.documentKey
                const document = store_key && localStorage.getItem(store_key)
                return document && JSON.parse(document)
            },
            documentWidth: () => {
                let workspaceEl = document.getElementById('workspace')
                return workspaceEl?.offsetWidth || 0
            },
            documentKey: (state, getters) => {
                const docType = getters.documentType
                return state.id ? `state_${state.id}` : docType && `state_${docType}`
            },
            allDocumentTypes: () => {
                return [...worksheet_types, ...bingo_types, ...flashcard_types]
            },
            allActivities: () => {
                return worksheet_activities_types
            },
            persistAction: (state) => {
                const stateHasId = Boolean(state.id)
                const windowDocHasId = Boolean(window?.doc?.id)
                return stateHasId || windowDocHasId
                    ? 'document/requestUpdateDocument'
                    : 'document/saveCompleteDocumentRequest'
            },
            immediatePersistAction: (state) => {
                const stateHasId = Boolean(state.id)
                const windowDocHasId = Boolean(window?.doc?.id)
                return stateHasId || windowDocHasId ? 'document/updateDocument' : 'document/saveCompleteDocumentRequest'
            },
            documentTypes: (_, getters) => {
                if (getters.isWorksheet) {
                    return worksheet_types
                } else if (getters.isBingo) {
                    return bingo_types
                } else {
                    return flashcard_types
                }
            },
            documentType: (state, getters) => {
                if (getters.isWorksheet) {
                    return worksheet_types[0].type
                } else if (getters.isBingo) {
                    return bingo_types[0].type
                } else if (getters.isFlashcard) {
                    return flashcard_types[0].type
                }
            },
            documentFonts: (state, getters) => {
                let fonts
                if (getters.isWorksheet) {
                    fonts = getters.worksheetFonts
                } else if (getters.isBingo) {
                    fonts = getters.bingoFonts
                } else {
                    fonts = getters.flashcardFonts
                }
                return {
                    ...fonts,
                    student_info_font: state.student_info_font,
                    title_font: state.title_font,
                    instructions_font: getters.isWorksheet ? '' : getters.instructionsStyle.font,
                }
            },
            documentItems: (state, getters) => {
                return getters[`${state.entity_type}Items`] ?? []
            },
            documentInstructions: (state) => {
                if (!state?.entity_type) {
                    return
                }
                if (state.entity_type == 'worksheet') {
                    return
                }
                return state[state.entity_type].instruction
            },
            instructionsStyle: (state) => {
                if (state.entity_type == 'worksheet') {
                    return
                }
                return state[state.entity_type].instruction.style
            },
            allWordbanks: (state, getters) => {
                return getters.documentItems.filter((item) => item.type === 'word_bank')
            },
            getDocumentType: (state, getters) => {
                let documentType
                if (getters.isWorksheet) {
                    documentType = worksheet_types.find((wt) => wt.key === state.worksheet.type)?.name
                } else if (getters.isBingo) {
                    documentType = bingo_types[0].name
                } else {
                    documentType = flashcard_types[0].name
                }

                return documentType
            },
            documentStyle: (state, getters) => {
                return getters[`${state.entity_type}Style`]
            },
            getDocumentUrl: (state) => {
                let urlTitle = Boolean(state.title) ? kebabCase(state.title.replace(/(<([^>]+)>)/gi, '')) : ''
                if (!urlTitle) {
                    urlTitle = 'my-document'
                }
                return window.app_url + '/' + state.id + '/' + urlTitle
            },
            getBorderStyles: () => border_styles,
            documentRequiresPagination: (state) => {
                let document = {}

                //populate the document fillable values
                for (let i = 0; i < documentFillable.length; i++) {
                    document[documentFillable[i]] = state[documentFillable[i]]
                }
                document['items'] = state.worksheet.body.items
                document['fake_answers'] = state.fake_answers
                document['student_fields'] = state.student_fields

                return document
            },
            isPublishable: (state, getters, rootState) => {
                let formErrors = {}
                if (!state?.grade_levels?.length) {
                    formErrors['grade_levels'] = 'Please select one or more grade levels for this document.'
                }
                if (!state.subject) {
                    formErrors['subject'] = 'Please select a subject for your workshet.'
                } else if (state.subject == 'Other' && !state.subject_other) {
                    formErrors['subject'] = 'What subject is this document for?'
                }
                if (!rootState.user.user.occupation) {
                    formErrors['occupation'] = 'This is a required field.'
                }

                return formErrors
            },
            wordbank: (state, getters) => {
                return getters[`${state.entity_type}Wordbank`]
            },
            wordbankStyle: (state, getters) => {
                return getters[`${state.entity_type}WordbankStyle`]
            },
            answerable: (state, getters) => {
                if (state.entity_type !== 'worksheet') {
                    return false
                }

                const alwaysOn = getters.documentItems.find((a) => a.type === 'matching')
                if (alwaysOn) return true

                const conditionalOn = getters.documentItems.find((a) => {
                    switch (a.type) {
                        case 'fill_in_the_blank':
                            if (a.data.multiple_choice) {
                                const correctAnswer = a.data.choices.find((opt) => opt.correct)
                                return Boolean(correctAnswer)
                            }
                            const blankWord = a.data.words.find((word) => word.partials.length)
                            return Boolean(blankWord)
                        case 'multiple_choice':
                            if (a.data.hasBooleanOptions) return true
                            const correctAnswer = a.data.options.find((opt) => opt.correct)
                            return Boolean(correctAnswer)
                        case 'open_response':
                            return a.data.has_answer
                        case 'word_scramble':
                            return (a.data.terms && a.data.terms.length) || (a.data.subtitle && a.data.subtitle.length)
                        default:
                            return false
                    }
                })

                return Boolean(conditionalOn)
            },
            defaultDocumentTitle(state, rootGetters) {
                switch (state.entity_type) {
                    case 'flashcard':
                        return rootGetters['abtests/flashcardDefaultTitle'] //|| flashcardDefaultTitleOption
                    case 'bingo':
                        return bingoDefaultTitleOption
                    case 'worksheet':
                    default:
                        return worksheetDefaultTitleOption
                }
            },
            flashcardHeaderHeight: (state) => state.flashcardHeaderHeight,
            pageCount: (state) => state.page_count,
            goPremium: (state) => state.goPremium,
            showAnswerKey: (state) => state.show_answer_key,
            filenameMaxLength: (state) => state.filenameMaxLength,
            showImageUploader: (state) => state.show_image_uploader,
            isInlineImage: (state) => state.is_inline_image,
            inlineImageUpload: (state) => state.inline_image_upload,
            saveError: (state) => state.saveError,
            mainDocument: (state) => state.mainDocument,
            isScrollingSideBar: (state) => state.isScrollingSideBar,
            isImageUploaderOpen: (state) => state.isImageUploaderOpen,
            plainDocumentTitle: (state) => (Boolean(state.title) ? state.title.replace(/(<([^>]+)>)/gi, '') : ''),
            flashcardHeightEmptyPage: (state) => state.flashcardHeightEmptyPage,
            isPublished: (state) => state.is_published,
            titleUpdated: (state) => state.titleUpdated,
            isFirstDocumentBeingPublished: (state, getters, rootState) => {
                const numberOfDocumentsWithAFirstPublishedAt = rootState.user.user.historical_published_documents_count
                return numberOfDocumentsWithAFirstPublishedAt === 0
            },
        }
    }

    actions() {
        return {
            ...super.actions(),
            setOnboarding({ commit }, payload) {
                commit('SET_ONBOARDING', payload)
            },
            setOnPublishing({ commit }, payload) {
                commit('SET_ONPUBLISHING', payload)
            },
            setSelected({ commit, state }, payload) {
                commit('SET_SELECTED', payload)
            },
            setOmniStudent({ commit }, payload) {
                commit('SET_OMNI_STUDENT', payload)
            },
            setWidgetStatus({ commit, state }, payload) {
                commit('SET_WIDGET_STATUS', payload)
            },
            setApiCallFeedback({ state }, error) {
                state.error = error
            },
            setDocumentType({ getters, commit, dispatch, rootGetters }, documentType) {
                const allDocTypes = getters.allDocumentTypes

                const matchedType = allDocTypes.find((dt) => dt.key === documentType)

                const docType = matchedType ? matchedType : allDocTypes[0]

                if (!window.doc.id && window.doc.created_at) {
                    window.history.replaceState({}, '', `/${docType.type}`)
                }
                commit('SET_DOCUMENT_TYPE', docType.type)
            },
            setTitle({ dispatch }) {
                let documentTitle = checkIfTitleWasSet() ? window.doc.title : null
                dispatch('setDocument', {
                    title: documentTitle,
                })
            },
            initDocumentFilename({ state, getters }) {
                if (!state.filename) {
                    state.filename =
                        getters.plainDocumentTitle !== getters.defaultDocumentTitle && state.title
                            ? state.title.replace(/(<([^>]+)>)/gi, '').substring(0, state.filenameMaxLength)
                            : ``
                }
            },
            setVueInstance({ state }, payload) {
                state.vue = payload
            },
            setModalInstance({ state }, payload) {
                state.$bvModal = payload
            },
            setDocumentFont({ dispatch }, { field, font }) {
                if (['title_font', 'student_info_font'].includes(field)) {
                    dispatch('setDocument', {
                        [field]: font,
                    })
                } else if (field === 'instructions_font') {
                    dispatch('setInstructionStyle', {
                        font,
                    })
                } else if (field === 'body_font') {
                    dispatch('setDocumentStyle', {
                        font,
                    })
                } else if (field) {
                    dispatch('setWordbankStyle', {
                        font,
                    })
                }
            },
            setSaveDocument({ commit }, value) {
                commit('SET_SAVE_WORKSHEET', value)
            },
            setDocumentMedia({ commit }, documentMedia) {
                commit('SET_DOCUMENT_MEDIA', documentMedia)
            },
            setDocument({ dispatch, commit }, document) {
                commit('SET_DOCUMENT_VALUES', document)
                if (document.entity) {
                    const type = document.entity_type || 'worksheet'
                    const actionMap = {
                        worksheet: 'setWorksheet',
                        bingo: 'setBingo',
                        flashcard: 'setFlashcard',
                    }
                    dispatch(actionMap[type], document.entity)
                }
            },
            setDocumentStyle({ state, dispatch }, style) {
                dispatch(`set${capitalizeFirstLetter(state.entity_type)}Style`, style)
            },
            resetDocumentStyle({ state, dispatch }) {
                dispatch(`reset${capitalizeFirstLetter(state.entity_type)}Style`)
            },
            setDefaultDocumentBody({ state, dispatch }, value) {
                dispatch(`setDefault${capitalizeFirstLetter(state.entity_type)}Body`, value)
            },
            setDocumentInstructions({ commit }, payloads) {
                commit('SET_DOCUMENT_INSTRUCTION', payloads)
            },
            setInstructionStyle({ commit }, payloads) {
                commit('SET_DOCUMENT_INSTRUCTION_STYLE', payloads)
            },
            storeDocumentState({ commit, getters }) {
                const store_key = getters.documentKey

                store_key &&
                    localStorage.setItem(
                        store_key,
                        JSON.stringify({
                            ...getters.documentModel,
                            updated_at: Math.floor(Date.now() / 1000),
                        }),
                    )

                commit('SET_HAS_MODIFICATION', true)
            },
            resetHandwritingFonts({ commit, getters }) {
                commit('RESET_HANDWRITING_FONTS', { getters })
            },
            setItemsFormatAt({ commit, getters }, payloads) {
                commit('FORMAT_NUMBERS_AT', { getters, ...payloads })
                commit('RENUMBER_ITEMS', { getters })
            },
            restartFromNext({ commit, getters }, index) {},
            renumberItems({ commit, getters }) {
                commit('RENUMBER_ITEMS', { getters })
            },
            setDocumentFromStore({ dispatch }, document) {
                const newDocument = {
                    media: {},
                    locked: true,
                    last_saved_at: document.updated_at,
                }

                Object.keys(document).forEach((key) => {
                    if (documentFillable.includes(key)) {
                        // If we're getting a null value skip...
                        if (document[key] !== null) {
                            newDocument[key] = document[key]
                        }
                    }
                })

                // Set document id
                if (document.id) {
                    newDocument.id = document.id
                }

                if (document.user_id) {
                    newDocument.user_id = document.user_id
                }

                if (document.user_has_purchased) {
                    newDocument.user_has_purchased = document.user_has_purchased
                }

                // Set document entity
                if (document.entity) {
                    newDocument.entity = document.entity
                }

                // Set images & PDF's.
                if (document.image) {
                    newDocument.media.image = document.image
                }
                if (document.thumb) {
                    newDocument.media.thumb = document.thumb
                }
                if (document.bordered_image) {
                    newDocument.media.bordered_image = document.bordered_image
                }
                if (document.pdfs) {
                    newDocument.media.pdfs = document.pdfs
                }

                // Set document grade levels
                if (document.grade_levels) {
                    newDocument.grade_levels = document.grade_levels
                }

                // set the collected values
                if (!document.id && document.entity_type) {
                    let event = StatsigEvent.NEW_WORKSHEET_DOCUMENT

                    if (document.entity_type == 'bingo') {
                        event = StatsigEvent.NEW_BINGO_DOCUMENT
                    } else if (document.entity_type == 'flashcard') {
                        event = StatsigEvent.NEW_FLASHCARD_DOCUMENT
                    }

                    dispatch(
                        'abtests/logStatsigEvent',
                        {
                            event: event,
                            value: getEntityStatsigValue(document.entity_type),
                        },
                        { root: true },
                    )
                    dispatch('setDocumentType', document.entity_type)
                }

                newDocument.first_published_at = document?.first_published_at

                dispatch('setDocument', newDocument)
            },
            confirmRestoreState({ commit, dispatch }, { document, lastState }) {
                // eslint-disable-next-line no-alert
                if (confirm('Unsaved changes were found. Do you want to restore unsaved changes?')) {
                    // Save it!
                    dispatch('setDocumentFromStore', lastState)
                    commit('SET_HAS_MODIFICATION', true)
                } else {
                    // remove saved state
                    let docType = document.entity_type
                    if (document?.entity?.type) {
                        docType = document?.entity?.type
                    }
                    const store_key = document.id ? `state_${document.id}` : docType && `state_${docType}`
                    localStorage.removeItem(store_key)

                    dispatch('setDocumentFromStore', document)
                }
            },
            setWindowDocument({ dispatch, commit }, document) {
                if (!Boolean(document)) return

                let docType = document.entity_type || 'worksheet'
                if (document.entity_type === 'flashcard') {
                    if (!document.entity?.page_setup) {
                        document.entity = {
                            page_setup: {
                                flashcard_type: 'standard',
                                flashcard_print_type: 'single-side',
                                flipcard_type: 'horizontal',
                            },
                        }
                    }
                }
                if (document.entity_type) {
                    docType = document.entity_type
                }
                const store_key = document.id ? `state_${document.id}` : docType && `state_${docType}`

                const stored_values = localStorage.getItem(store_key)
                try {
                    const lastState = stored_values && JSON.parse(stored_values)
                    const statedTime = lastState?.updated_at || 0
                    const updatedTime = document.updated_timestamp || 0
                    const copy_edit = document.copy_of_document_id
                    if (
                        document.id &&
                        !document.is_published &&
                        statedTime > updatedTime &&
                        statedTime - updatedTime <= 10000 &&
                        !copy_edit
                    ) {
                        lastState.id = document.id
                        dispatch('setDocumentFromStore', lastState)
                        commit('SET_HAS_MODIFICATION', true)
                    } else if (document.id && !document.is_published && statedTime > updatedTime && !copy_edit) {
                        lastState.id = document.id
                        dispatch('confirmRestoreState', {
                            document,
                            lastState,
                        })
                    } else if (!document.id && lastState && !copy_edit) {
                        if ((Math.floor(Date.now() / 1000) - lastState.updated_at ?? 0) < 30) {
                            // Just restore previous state

                            dispatch('setDocumentFromStore', lastState)
                            commit('SET_HAS_MODIFICATION', true)
                        } else {
                            dispatch('confirmRestoreState', {
                                document,
                                lastState,
                            })
                        }
                    } else {
                        dispatch('setDocumentFromStore', document)
                    }
                } catch (e) {
                    dispatch('setDocumentFromStore', document)
                }

                dispatch('setWorkingDocument', document.id)
            },
            loadLatestPublishSettings({ state, rootGetters, commit }) {
                const isLoggedIn = rootGetters['user/isLoggedIn']
                if (isLoggedIn) {
                    return DocumentApi.getLatest(state.entity_type).then((res) => {
                        if (res.status === 200) {
                            const doc = res.data
                            const lastPS = {
                                subject: doc.subject,
                                subject_other: doc.subject_other,
                                grade_levels: doc.grade_levels,
                            }
                            commit('SET_DOCUMENT_PUBLISH_SETTINGS', lastPS)
                        }
                    })
                }
            },
            async publish({ state, getters, commit, dispatch, rootState }) {
                try {
                    state.is_publishing = true

                    const statsigEvent = getters.isFirstDocumentBeingPublished
                        ? StatsigEvent.PUBLISH_FIRST_DOCUMENT
                        : StatsigEvent.PUBLISH_DOCUMENT

                    commit('CLEAR_DOCUMENT_MEDIA')
                    dispatch(
                        'abtests/logStatsigEvent',
                        {
                            event: statsigEvent,
                            value: state.id,
                        },
                        { root: true },
                    )

                    await DocumentApi.publish(state.entity_type, state.id)

                    let sinceFirstPublished = 0
                    let sinceLastPublished = 0
                    if (rootState.user.user.first_published_at) {
                        const firstPublishTime = new Date(rootState.user.user.first_published_at)
                        sinceFirstPublished = Math.floor((Date.now() - firstPublishTime.getTime()) / 24 / 3600 / 1000)
                    }
                    if (rootState.user.user.last_published_at) {
                        const lastPublishedTime = new Date(rootState.user.user.last_published_at)
                        sinceLastPublished = Math.floor((Date.now() - lastPublishedTime.getTime()) / 24 / 3600 / 1000)
                    }

                    if (!state.first_published_at) {
                        dispatch(
                            'abtests/logStatsigEvent',
                            {
                                event: StatsigEvent.PUBLISH_SUCCESS,
                                value: state.id,
                                payload: {
                                    sinceFirstPublished,
                                    sinceLastPublished,
                                },
                            },
                            { root: true },
                        )
                    } else {
                        dispatch(
                            'abtests/logStatsigEvent',
                            {
                                event: StatsigEvent.REPUBLISH_SUCCESS,
                                value: state.id,
                                payload: {
                                    sinceFirstPublished,
                                    sinceLastPublished,
                                },
                            },
                            { root: true },
                        )
                    }

                    await EventApi.create_event(UserEvent.LEAVING, state.id)
                    localStorage.setItem('DocumentZoom', JSON.stringify({ id: state.id, zoom: state.zoom }))
                    window.location.replace(getters.getDocumentUrl)
                } catch (error) {
                    state.is_publishing = false
                    if (error.response) {
                        state.vue.$bvToast.toast('There was an error publishing this document.', dangerToast)
                        return
                    }

                    await wmAlert.confirm({
                        title: 'There was an error publishing this document.',
                        html: 'Check your network status and try again.',
                        confirmButtonText: 'Ok',
                        showDenyButton: false,
                    })

                    throw error
                }
            },
            async saveCompleteDocumentRequest({ commit, dispatch, getters, state, rootGetters }) {
                commit('SET_VALUE', { saveError: false })
                const isLoggedIn = rootGetters['user/isLoggedIn']

                if (!state.saving && isLoggedIn) {
                    return new Promise((resolve, reject) => {
                        //if we get called and the document already has an ID resolve
                        let document = getters.documentModel

                        commit('SET_IS_LOADING', true)
                        commit('SET_SAVING_DOCUMENT', true)

                        if (document.filename.trim() == '') {
                            const title = Boolean(document.title) ? document.title.replace(/(<([^>]+)>)/gi, '') : ''
                            const content = state.filenameEdited
                                ? document.filename
                                : title !== getters.defaultDocumentTitle
                                  ? title
                                  : ``
                            const filename = filenameFromTitle(content, document.entity_type).substring(0, this.maxLength)
                            dispatch('setDocument', { filename })
                            document.filename = filename
                        }

                        DocumentApi.create(document.entity_type, document)
                            .then((response) => {
                                state.last_saved_at = response.data.updated_at
                                dispatch('removeSavedState')
                                dispatch('setDocument', {
                                    id: response.data.id,
                                    filename: document.filename,
                                    entity_id: response.data.entity_id,
                                    last_saved_at: response.data.updated_at,
                                    user_id: response.data.user_id,
                                    entity: response.data.entity,
                                    entity_type: response.data.entity_type,
                                    title: response.data.title,
                                })

                                window.doc.id = response.data.id
                                window.doc.title = response.data.title

                                // update the URL
                                window.history.replaceState({}, '', `/${document.entity_type}-maker/${response.data.id}`)

                                state.vue?.$bvToast.toast('Your document saved successfully.', successToast)

                                dispatch(
                                    'abtests/logStatsigEvent',
                                    {
                                        event: StatsigEvent.NEW_DOCUMENT,
                                        value: getEntityStatsigValue(document.entity_type),
                                    },
                                    { root: true },
                                )

                                dispatch('setUpDocumentUpdateInterval')
                                resolve()
                            })
                            .catch((error) => {
                                dispatch('setSaveDocument', false)
                                commit('SET_VALUE', { saveError: true })

                                dispatch('setApiCallFeedback', 'There was an error saving this document.')

                                if (error.response && error.response.status === 422) {
                                    let errors = Object.values(error.response.data.errors)
                                    state.vue.$bvToast.toast(errors.join(' '), dangerToast)
                                } else if (error.response) {
                                    state.$bvModal.open('error_handling')
                                }

                                if (state.is_publishing) {
                                    wmAlert.confirm({
                                        title: 'There was an error publishing this document.',
                                        html: 'Check your network status and try again.',
                                        confirmButtonText: 'Ok',
                                        showDenyButton: false,
                                    })
                                }
                                reject(error)
                                resolve()
                            })
                            .finally(() => {
                                commit('SET_IS_LOADING', false)
                                commit('SET_SAVING_DOCUMENT', false)
                            })
                    })
                } else {
                    commit('SET_HAS_MODIFICATION', true)
                }
            },
            setUpDocumentUpdateInterval({ state, dispatch, getters, commit }) {
                if (state.updateInterval) {
                    commit('REMOVE_DOCUMENT_UPDATE_INTERVAL')
                }
                commit(
                    'SET_DOCUMENT_UPDATE_INTERVAL',
                    setInterval(async () => {
                        if (!state.hasModification) return

                        const document = getters.documentModel

                        try {
                            await dispatch('updateDocumentRequest', document)
                        } catch (error) {
                            await dispatch('setSaveDocument', false)
                            commit('SET_VALUE', { saveError: true })
                        }
                    }, UPDATE_INTERVAL),
                )
            },
            async updateDocument({ commit, state, getters, dispatch }, showToast = false) {
                try {
                    commit('SET_VALUE', { saveError: false })

                    await dispatch('setSaveDocument', true)
                    await dispatch('updateDocumentRequest', getters.documentModel)

                    if (showToast) {
                        state.vue.$bvToast.toast('Your document saved successfully.', successToast)
                    }
                } catch (error) {
                    await dispatch('setSaveDocument', false)
                    commit('SET_VALUE', { saveError: true })
                }
            },
            requestUpdateDocument({ dispatch }) {
                dispatch('setSaveDocument', true)
            },
            updateDocumentRequest({ commit, dispatch, state, getters }, document) {
                return new Promise((resolve, reject) => {
                    if (!state.save_document) {
                        reject(new Error('Document not saved. `state.save_document` is false.'))
                    }

                    const document_id = window.doc.id || state.id
                    const document_type = state.entity_type || window.doc.entity_type

                    if (document.filename.trim() == '') {
                        const title = Boolean(document.title) ? document.title.replace(/(<([^>]+)>)/gi, '') : ''
                        const content = state.filenameEdited
                            ? document.filename
                            : title !== getters.defaultDocumentTitle
                              ? title
                              : ``
                        const filename = filenameFromTitle(content, document.entity_type).substring(0, this.maxLength)
                        dispatch('setDocument', { filename })
                        document.filename = filename
                    }

                    if (!document_id) {
                        return
                    }

                    if (!document.entity.id) document.entity.id = state.entity_id

                    DocumentApi.update(document_type, document_id, document)
                        .then((res) => {
                            commit('SET_LAST_UPDATED', res.data.updated_at)
                            commit('SET_HAS_MODIFICATION', false)
                            dispatch('removeSavedState')
                            resolve(res.data)
                        })
                        .catch((error) => {
                            commit('SET_VALUE', { saveError: true })
                            dispatch('setApiCallFeedback', 'There was an error updating this document.')

                            if (error.response && error.response.status === 422) {
                                let errors = Object.values(error.response.data.errors)
                                state.vue.$bvToast.toast(errors.join(' '), dangerToast)
                            } else if (error.response) {
                                state.$bvModal.open('error_handling')
                            }

                            if (state.is_publishing) {
                                wmAlert.confirm({
                                    title: 'There was an error publishing this document.',
                                    html: 'Check your network status and try again.',
                                    confirmButtonText: 'Ok',
                                    showDenyButton: false,
                                })
                            }

                            commit('REMOVE_DOCUMENT_UPDATE_INTERVAL')

                            //log this error for debugging purposes
                            apiClient.post('/error', {
                                session_attributes_id: window.session_attributes_id,
                                url: '',
                                line: '',
                                column: '',
                                message: error.message,
                                stack: '',
                            })

                            reject(error)
                        })
                })
            },
            insertWordsAt({ commit, getters, dispatch }, payloads) {
                commit('INSERT_WORDS_AT', { getters, ...payloads })
                dispatch('storeDocumentState')
            },
            toggleWordBlank({ commit, getters, dispatch }, payloads) {
                commit('TOGGLE_WORD_BLANK', { getters, ...payloads })
                dispatch('storeDocumentState')
            },
            updateWords({ commit, getters, dispatch }, payloads) {
                commit('UPDATE_WORDS', { getters, ...payloads })
                dispatch('storeDocumentState')
            },
            updateMultipleChoices({ commit, getters, dispatch }, payloads) {
                commit('UPDATE_MULTIPLE_CHOICES', { getters, ...payloads })
                dispatch('storeDocumentState')
            },
            updateItem({ commit, dispatch, state, getters }, record) {
                let itemCopy = getters.isWorksheet
                    ? { ...state.worksheet.body.items[record.index] }
                    : {
                          ...state[state.entity_type].content.items[record.index],
                      }
                let keys = Object.keys(record.item)
                keys.forEach((key) => {
                    itemCopy[key] = record.item[key]
                })

                commit('UPDATE_ITEM', { index: record.index, item: itemCopy })
                dispatch('storeDocumentState')
            },
            copyItem({ commit, getters }, index) {
                commit('COPY_ITEM_AT', { at: index, getters })
                commit('RENUMBER_ITEMS', { getters })
            },
            duplicateItem({ commit, getters, dispatch }, index) {
                commit('DUPLICATE_ITEM_AT', { at: index, getters })
                commit('RENUMBER_ITEMS', { getters })
                dispatch('storeDocumentState')
            },
            pushNewItem({ state, commit, getters, dispatch }, content = null) {
                commit('PUSH_NEW_ITEM', { getters, content })
                if (state.entity_type === 'worksheet') {
                    commit('RENUMBER_ITEMS', { getters })
                }
                dispatch('storeDocumentState')
            },
            async pushNewItemWithTypeAt({ state, commit, getters, dispatch }, payload) {
                let item = {}
                const items = getters.documentItems
                const referenceItem = await dispatch('findNearestItemIndex', payload.index)

                if (Boolean(referenceItem)) {
                    await dispatch('setWidgetStatus', {
                        focusedItem: items[referenceItem],
                    })

                    item = items[referenceItem].clone()
                    item.clearItemData()
                } else {
                    item = new Activity(state.selected)
                }

                if (state.selected === 'word_bank') {
                    const wordbank = new Activity(state.selected)
                    const wordbankIndex = getters.allWordbanks.length
                    const usedColors = getters.allWordbanks.map((w) => w.data.color)

                    wordbank.data.setWordbankColor(wordbankIndex, usedColors)
                    item.data.color = wordbank.data.color
                }
                commit('PUSH_NEW_ITEM_AT', {
                    item,
                    index: payload.index,
                    getters,
                })

                commit('RENUMBER_ITEMS', { getters })

                await dispatch('setWidgetStatus', {
                    focusedItem: item,
                })

                await dispatch('storeDocumentState')
            },
            findNearestItemIndex({ commit, getters, state }, insertionIndex) {
                if (!Boolean(getters.documentItems.length)) return null

                const items = cloneDeep(getters.documentItems)
                const type = state.selected

                let referenceItemAboveIndex
                let referenceItemBelowIndex

                for (let i = insertionIndex - 1; i >= 0; i--) {
                    if (items[i].type === type) {
                        referenceItemAboveIndex = i
                        break
                    }
                }

                for (let i = insertionIndex; i < items.length; i++) {
                    if (items[i].type === type) {
                        referenceItemBelowIndex = i
                        break
                    }
                }

                insertionIndex--

                if (
                    referenceItemAboveIndex !== undefined &&
                    (referenceItemBelowIndex === undefined ||
                        insertionIndex - referenceItemAboveIndex <= referenceItemBelowIndex - insertionIndex)
                ) {
                    return referenceItemAboveIndex
                }

                return referenceItemBelowIndex
            },
            pushNewItemWithType({ commit, getters, dispatch }, payload) {
                commit('PUSH_NEW_ITEM', {
                    item: new Activity(payload.type),
                    getters,
                })
                commit('RENUMBER_ITEMS', { getters })
                dispatch('storeDocumentState')
            },
            exchangeItems({ commit, getters, dispatch }, payload) {
                commit('EXCHANGE_ITEMS', { ...payload, getters })
                dispatch('storeDocumentState')
            },
            removeSavedState({ state, getters }) {
                const store_key = getters.documentKey
                if (!store_key) return

                // Get the prefix pattern from store_key (e.g., "state_" from "state_123")
                const prefix = store_key.split('_')[0] + '_'

                // Remove all items from localStorage that start with the same prefix
                Object.keys(localStorage).forEach((key) => {
                    if (key.startsWith(prefix)) {
                        localStorage.removeItem(key)
                    }
                })
                state.hasModification = false
            },
            removeItem({ commit, getters, dispatch }, index) {
                const deleteItem = getters.documentItems[index]
                if (deleteItem.data?.wordbank_id) {
                    commit('REMOVE_FROM_WORDBANK', {
                        wordbank_id: deleteItem.data?.wordbank_id,
                        index,
                        getters,
                    })
                }
                commit('REMOVE_ITEM', index)
                commit('RENUMBER_ITEMS', { getters })
                dispatch('storeDocumentState')
            },
            dragSortItems({ commit, getters, dispatch }, items) {
                commit('CLEAR_ITEMS')
                let display_order = 0
                items.forEach((item) => {
                    //set display order
                    item.display_order = display_order

                    commit('PUSH_NEW_ITEM', { item, getters })

                    display_order++
                })

                commit('RENUMBER_ITEMS', { getters })
                dispatch('storeDocumentState')
            },
            sortItems({ dispatch, getters, state }, sorting) {
                let items = copyObject(getters.documentItems)
                let adjustedCompareValue = (value) => value.replace(/(<([^>]+)>)/gi, '').toUpperCase()
                items.sort((act1, act2) => {
                    let [a, b] = [act1, act2]
                    let val1 = adjustedCompareValue(a[sorting.field])
                    let val2 = adjustedCompareValue(b[sorting.field])

                    if (val1 < val2) {
                        if (sorting.direction == 'asc') {
                            return -1
                        } else {
                            return 1
                        }
                    } else if (val1 > val2) {
                        if (sorting.direction == 'desc') {
                            return -1
                        } else {
                            return 1
                        }
                    }
                    return 0
                })

                dispatch('document/setItems', { items: items, allowEmpty: true }, { root: true })

                dispatch('storeDocumentState')
            },
            randomizeItems({ dispatch, state }) {
                let items = copyObject(state[state.entity_type].content.items)

                let c = new Chance(Math.floor(Math.random() * 10))

                items = c.shuffle(items)

                dispatch('document/setItems', { items: items, allowEmpty: true }, { root: true })

                dispatch('storeDocumentState')
            },
            clearItems({ commit, dispatch }) {
                commit('CLEAR_ITEMS')
                dispatch('deleteInlineImages')
                dispatch('pushNewItem')
            },
            setItems({ dispatch, commit, getters }, { items, allowEmpty }) {
                commit('CLEAR_ITEMS')

                items.forEach((item) => {
                    if (!allowEmpty && !item.data) return

                    let newItem = typeof item === Activity ? item.clone() : item

                    newItem.id = item.id

                    newItem.display_order = item.display_order || 0

                    commit('PUSH_NEW_ITEM', { item: newItem, getters })
                })

                dispatch('storeDocumentState')
            },
            swapItem({ commit, dispatch }, index) {
                commit('SWAP_ITEM', index)
                dispatch('storeDocumentState')
            },
            setStudentField({ commit, dispatch }, value) {
                commit('SET_STUDENT_FIELD', value)
                dispatch('storeDocumentState')
            },
            pushStudentField({ commit, dispatch }, value) {
                commit('PUSH_STUDENT_FIELD', value)
                dispatch('storeDocumentState')
            },
            removeStudentField({ commit, dispatch }, index) {
                commit('REMOVE_STUDENT_FIELD', index)
                dispatch('storeDocumentState')
            },
            changeShuffleSeed({ dispatch, getters }, times) {
                if (!times) {
                    times = 5
                }
                let promises = []
                let delay = 1
                for (let i = 0; i < times; i++) {
                    promises.push(
                        new Promise((resolve) => {
                            setTimeout(() => {
                                if (getters.isWorksheet) {
                                    dispatch('setWorksheetBody', {
                                        shuffle_seed: Math.floor(Math.random() * 9999),
                                    })
                                } else if (getters.isBingo) {
                                    dispatch('setBingo', {
                                        shuffle_seed: Math.floor(Math.random() * 9999),
                                    })
                                }
                                resolve()
                            }, delay)
                            delay += 100
                        }),
                    )
                }

                // when all the items have been saved set is_loading to false
                Promise.all(promises).then(() => {
                    dispatch('storeDocumentState')
                })
            },
            addNewWordbank({ commit, getters }, payloads) {
                commit('ADD_NEW_WORDBANK', {
                    getters,
                    ...payloads,
                })
            },
            updateSpecificFilter({ commit, getters, dispatch }, payloads) {
                commit('UPDATE_PARTIAL_WORD_SPECIFIC_FILTERS', {
                    getters,
                    ...payloads,
                })
                dispatch('storeDocumentState')
            },
            updatePartialWord({ commit, getters, dispatch }, payloads) {
                commit('UPDATE_PARTIAL_WORD', {
                    getters,
                    ...payloads,
                })
                dispatch('storeDocumentState')
            },
            updateWordbank({ commit, getters, dispatch }, payloads) {
                commit('UPDATE_WORDBANK', {
                    getters,
                    ...payloads,
                })
                dispatch('storeDocumentState')
            },
            removeFromWordbank({ commit, getters, dispatch }, payloads) {
                commit('REMOVE_FROM_WORDBANK', {
                    getters,
                    ...payloads,
                })
                dispatch('storeDocumentState')
            },
            setWordbank({ dispatch, state }, wordbank) {
                dispatch(`set${capitalizeFirstLetter(state.entity_type)}Wordbank`, wordbank)
                dispatch('storeDocumentState')
            },
            setWordbankStyle({ state, dispatch }, style) {
                dispatch(`set${capitalizeFirstLetter(state.entity_type)}WordbankStyle`, style)
                dispatch('storeDocumentState')
            },
            adjustZoomContainer({ state, dispatch }, ratio = 1) {
                let mediumSize = WINDOW_MEDIUM_SIZE
                if (state.flashcard.page_setup.flashcard_type === '3x5') {
                    mediumSize = 1550
                } else {
                    state.zoom = ratio
                    dispatch('scaleDocument', true)
                    return
                }
                let zoomContainer = document.getElementById('zoomContainer')
                let workspace = document.getElementById('workspace')
                if (zoomContainer && workspace) {
                    zoomContainer.style.width = workspace.clientWidth * ratio + 'px'
                }

                if (document.documentElement.clientWidth <= mediumSize) {
                    state.zoom = 'Fit'
                    dispatch('scaleDocument', true)
                }
            },
            paginateItems({ commit, getters, dispatch }) {
                const documentItems = getters.documentItems

                //get the container height
                let container = document.getElementsByClassName('printable-area') //[0].offsetHeight
                //get the header height
                let header = document.getElementsByClassName('complete-document-header') //[0].offsetHeight
                //add up the complete header size

                let refs = document.getElementsByClassName('document-items')

                let remainingSpace
                //we need to ensure the elements exist before referencing them
                Vue.nextTick(() => {
                    if (refs.length) {
                        if (container[0]) {
                            container = container[0].offsetHeight
                        }
                        if (header[0]) {
                            header = header[0].offsetHeight
                        }
                    }
                    remainingSpace = container - header
                })

                //set our default documents array
                commit('SET_DOCUMENTS', [])

                let documents = [{ body: { items: [] } }]

                //loop through the values rendered in our hidden document
                for (let i = 0; i < refs.length; i++) {
                    //get the element from the refs
                    let elem = refs[i]

                    const hasPageBreak = elem.getElementsByClassName('document-page-break').length > 0

                    //get the element height
                    let h = elem.offsetHeight

                    // this is another check for when to paginate
                    let blankBolean = false

                    // ---->>> begining of nextTick <<<----
                    // filter when the page if fully loaded dont remove this, otherwise it will throw an error that is not finding the element
                    Vue.nextTick(() => {
                        let blank = documentItems[i]
                        if (blank && blank.type === 'blank_space') {
                            if (blank.data.height * 96 + 10 > remainingSpace) {
                                blankBolean = true
                            }
                        }

                        //is the height of this element greater than our remaining space?
                        if (h > remainingSpace || hasPageBreak || blankBolean === true) {
                            //create a new page
                            documents.push({ body: { items: [] } })

                            //reset remaining space to the container height for subsequent documents
                            remainingSpace = container
                        }

                        //continue the loop adding elements to the last page in our array
                        const currentItem = documentItems[i]
                        if (!currentItem) return

                        if (currentItem.type !== 'page_break') {
                            documents[documents.length - 1].body.items.push({
                                ...documentItems[i],
                                display_order: i,
                            })
                        } else {
                            documents[Math.max(0, documents.length - 2)].body.items.push({
                                ...documentItems[i],
                                display_order: i,
                            })
                        }

                        //decrease our remaining space by the height of the last element
                        if (!hasPageBreak) {
                            remainingSpace = remainingSpace - h
                        }

                        dispatch('scaleDocument', true)
                    })
                    // ---->>> end of nextTick <<<----
                }

                //return the documents variable
                commit('SET_DOCUMENTS', documents)
            },
            paginateCallList({ commit, dispatch }, sortable) {
                //get the container height
                let container = document.getElementsByClassName('call-list-item2') //[0].offsetHeight

                let callListPages = {}

                let remainingSpace = container[0].offsetHeight
                let callListItems = document.getElementsByClassName('call-list-item')
                let page = 1
                let column = 1
                let previousSlice = 0

                for (let i = 0; i < callListItems.length; i++) {
                    //get the element from the callListItems
                    let elem = callListItems[i]
                    let hOffset = elem.offsetHeight
                    // Add the top and bottom margins of the child
                    let children = elem.children

                    let styles = window.getComputedStyle(children[0])
                    let totalHeight = hOffset + parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)
                    Vue.nextTick(() => {
                        //get the element height
                        if (remainingSpace && totalHeight > remainingSpace) {
                            if (!callListPages[page]) {
                                callListPages[page] = { column: {} }
                            }
                            if (!callListPages[page][column]) {
                                callListPages[page][column] = {}
                            }

                            let payloadData = sortable.slice(previousSlice, i)
                            callListPages[page][column]['data'] = payloadData
                            callListPages[page][column]['starts'] = previousSlice + 1
                            previousSlice = i
                            if (column == 2) {
                                page++
                                column = 1
                            } else {
                                column++
                            }
                            remainingSpace = container[0].offsetHeight - totalHeight
                        } else {
                            remainingSpace = remainingSpace - totalHeight
                        }

                        //Check if its last element
                        if (i == callListItems.length - 1) {
                            //Check if column 2 exists. If not add, if yes, create new page and column
                            if (column == 2) {
                                if (!callListPages[page][column]) {
                                    callListPages[page][column] = {}
                                }
                                callListPages[page][column]['data'] = sortable.slice(previousSlice, sortable.length)
                                callListPages[page][column]['starts'] = previousSlice + 1
                            } else {
                                page++

                                if (!callListPages[page]) {
                                    callListPages[page] = {}
                                }
                                if (!callListPages[page][column]) {
                                    callListPages[page][column] = {
                                        data: sortable.slice(previousSlice, sortable.length),
                                        starts: previousSlice + 1,
                                    }
                                }
                            }

                            commit('SET_CALLLIST_PAGES', callListPages)
                        }
                    })
                }
            },
            fetchDocumentPurchaseStatus({ commit, state }) {
                if (!state.user_id || !state.id) return

                apiClient.get(`account/user/${state.user_id}/purchases/${state.id}`).then((res) => {
                    if (res.data.purchase) {
                        commit('USER_HAS_PURCHASED_DOCUMENT', true)
                    }
                })
            },
            setFlashcardHeaderHeight({ commit }, payload) {
                commit('SET_FLASHCARD_HEADER_HEIGHT', payload)
            },
            resetDocumentHeader({ commit }) {
                commit('RESET_DOCUMENT_HEADER')
            },
            setPageCount({ commit }, count) {
                commit('SET_PAGE_COUNT', count)
            },
            resetSinglePurchaseEdits({ commit }) {
                commit('SET_SINGLE_PURCHASE_EDITS', 0)
            },
            setGoPremium({ commit }, val = true) {
                commit('SET_GO_PREMIUM', val)
            },
            setShowImageUploader({ commit, dispatch }, val = true) {
                dispatch('setReplaceImageId', '')
                commit('SET_SHOW_IMAGE_UPLOADER', val)
            },
            setIsInlineImage({ commit }, val = true) {
                commit('SET_IS_INLINE_IMAGE', val)
            },
            closeImageUploader({ commit }) {
                commit('SET_SHOW_IMAGE_UPLOADER', false)
            },
            setInlineImagesUpload({ commit }, payload) {
                commit('SET_INLINE_IMAGES_UPLOAD', payload)
            },
            async deleteInlineImages({ state, commit, dispatch }) {
                let requests = []
                const inline_images = state[state.entity_type].inline_images
                inline_images.forEach((image) => {
                    requests.push(ImageUploadApi.delete(image.objectId))
                })

                try {
                    await Promise.all(requests)
                } catch (error) {
                    console.error(error)
                }
                commit('CLEAR_INLINE_IMAGES')
                dispatch('storeDocumentState')
            },
            generatePdf({ commit }, payload) {
                return DocumentApi.pdf(payload.document_id, payload)
            },
            setSaveError({ commit }, error) {
                commit('SET_VALUE', { saveError: error })
            },
            async setWorkingDocument({ commit, rootGetters }, documentId) {
                const isLoggedIn = rootGetters['user/isLoggedIn']
                if (!isLoggedIn || !documentId) return

                const mainDocument = uuidv1()
                commit('SET_VALUE', { mainDocument: mainDocument })

                await apiClient.post('/working-document', {
                    documentId: documentId,
                    documentToken: mainDocument,
                })
            },
            setIsPublishing({ commit }, value) {
                commit('SET_VALUE', { is_publishing: value })
            },
            setIsScrollingSideBar({ commit }, value) {
                commit('SET_VALUE', { isScrollingSideBar: value })
            },
            setIsImageUploaderOpen({ commit }, value) {
                commit('SET_VALUE', { isImageUploaderOpen: value })
            },
            async resetDocument({ state, dispatch, getters, rootState, rootGetters }, document) {
                const documentId = document.id
                const userId = document.user_id
                const entity_type = document.entity_type

                // Remove local storage copy of the document
                await dispatch('removeSavedState')

                const isLoggedIn = rootGetters['user/isLoggedIn']
                const user = rootState.user.user
                let newDocument = {
                    ...initialize,
                    images: [],
                    documents: [],
                    filenameEdited: false,
                    is_publishing: false,
                    last_saved_at: false,
                    is_loading: false,
                    save_document: false,
                    saving: false,
                    updateInterval: undefined,
                    hasModification: false,
                    is_private: false,
                    is_published: false,
                    entity: {
                        id: null,
                        images: [],
                        inline_images: [],
                    },
                    entity_type,
                    user_id: isLoggedIn ? user.id : null,
                }

                // Force reactivity by setting window.doc to null first
                window.doc = null

                Vue.nextTick(async () => {
                    state.id = null
                    state.entity_id = null

                    window.doc = { ...newDocument }
                    dispatch('setWindowDocument', { ...window.doc })

                    if (isLoggedIn) {
                        // Delete the document if it exists
                        if (documentId && userId === user.id) {
                            await DocumentApi.delete(entity_type, documentId)
                        }
                        await dispatch('setSaveDocument', false)
                    }

                    window.history.replaceState({}, '', `/${entity_type}-maker`)
                })
            },
        }
    }

    mutations() {
        return {
            ...super.mutations(),
            SET_VALUE(state, values) {
                Object.keys(values).forEach((value) => {
                    state[value] = values[value]
                })
            },
            SET_ONBOARDING(state, payload) {
                state.onboarding = payload
            },
            SET_ONPUBLISHING(state, payload) {
                state.onpublishing = payload
            },
            SET_SELECTED(state, payload) {
                state.selected = payload.selected
            },
            SET_WIDGET_STATUS(state, payloads) {
                state.currentWidget = {
                    openHeader: false,
                    openInstructions: false,
                    pageSettings: false,
                    wordsDefinition: false,
                    bingoCardSetup: false,
                    openBingoWords: false,
                    bingoCallList: false,
                    focusedItem: undefined,
                }
                Object.keys(payloads).forEach((value) => {
                    state.currentWidget[value] = payloads[value]
                })
            },
            SET_OMNI_STUDENT(state, payload) {
                state.omni_student = payload
            },
            SET_DOCUMENT_VALUES(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state[value] = payloads[value]
                })
            },
            SET_DOCUMENT_TYPE(state, value) {
                state.entity_type = value
            },
            SET_SAVE_WORKSHEET(state, value) {
                state.save_document = value
            },
            SET_DOCUMENT_MEDIA(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.media[value] = payloads[value]
                })
            },
            SET_IS_LOADING(state, loading) {
                state.is_loading = loading
            },
            SET_SAVING_DOCUMENT(state, saving) {
                state.saving = saving
            },
            COPY_ITEM_AT(state, { at, getters }) {
                const documentItems = getters.documentItems
                const newItem = new Activity(documentItems[at].type)
                documentItems.splice(at + 1, 0, newItem)
            },
            DUPLICATE_ITEM_AT(state, { at, getters }) {
                const documentItems = getters.documentItems
                const newItem = documentItems[at].clone()
                newItem.id = uuidv1()

                if (state.entity_type === 'worksheet') {
                    state.worksheet.body.items.splice(at + 1, 0, newItem)
                    return
                }

                state[state.entity_type].content.items.push(item)
            },
            PUSH_NEW_ITEM(state, { item, getters, content }) {
                const documentItems = getters.documentItems.filter((i) => i.numberable)

                const lastItem = documentItems.length && documentItems.slice(-1)[0]

                if (item) {
                    if (item.numberable && lastItem) {
                        item.numbering.format = lastItem.numbering.format
                    }

                    //push the newItem into state.worksheet.body.items
                    if (state.entity_type === 'worksheet') {
                        state.worksheet.body.items.push(item)
                    } else {
                        state[state.entity_type].content.items.push(item)
                    }
                } else {
                    if (state.entity_type === 'worksheet') {
                        // define a new item copy
                        const itemType = state.worksheet?.type
                        item = lastItem ? lastItem.copy() : new Activity(itemType)

                        //add the new item to our list.
                        state.worksheet.body.items.push(item)
                    } else {
                        const newItem = { ...defaultDocumentItem }

                        //set display order
                        let lastItem = [...state[state.entity_type].content.items].pop()
                        if (lastItem) {
                            newItem.display_order = lastItem.display_order + 1
                            newItem.term = content.term
                            newItem.definition = content.definition
                            newItem.term_image = content.term_image
                            newItem.definition_image = content.definition_image
                        }

                        //add the new item to our list.
                        state[state.entity_type].content.items.push(newItem)
                    }
                }
            },
            PUSH_NEW_ITEM_AT(state, { item, index, getters }) {
                const documentItems = getters.documentItems.filter((i) => i.numberable)

                const lastItem = documentItems.length && documentItems.slice(-1)[0]

                if (item) {
                    if (item.numberable && lastItem) {
                        item.numbering.format = lastItem.numbering.format
                    }

                    // copy settings from lastest item
                    const last = getters.documentItems
                        .slice(0, index)
                        .filter((item) => mainDocumentTypes.includes(item.type))
                        .slice(-1)[0]

                    if (last) {
                        item?.data?.copySettingFrom && item.data.copySettingFrom(last)
                    }

                    //push the newItem into state.worksheet.body.items
                    if (state.entity_type === 'worksheet') {
                        state.worksheet.body.items.splice(index, 0, item)
                    } else {
                        state[state.entity_type].content.items.splice(index, 0, item)
                    }
                } else {
                    if (state.entity_type === 'worksheet') {
                        // define a new item copy
                        const itemType = state.worksheet?.type
                        item = lastItem ? lastItem.copy() : new Activity(itemType)
                        if (lastItem && itemType === 'word_bank') {
                            const wordbankIndex = getters.allWordbanks.length
                            const usedColors = getters.allWordbanks.map((w) => w.data.color)

                            item.data.setWordbankColor(wordbankIndex, usedColors)
                        }

                        //add the new item to our list.
                        state.worksheet.body.items.splice(index, 0, item)
                    } else {
                        const newItem = { ...defaultDocumentItem }

                        //set display order
                        let lastItem = [...state[state.entity_type].content.items].pop()
                        if (lastItem) {
                            newItem.display_order = lastItem.display_order + 1
                        }

                        //add the new item to our list.
                        state[state.entity_type].content.items.splice(index, 0, newItem)
                    }
                }
            },
            RESET_HANDWRITING_FONTS(state, { getters }) {
                const documentItems = getters.documentItems
                documentItems
                    .filter((i) => i.type === 'handwriting')
                    .forEach((hw) => {
                        hw.data.hw_font_print = STANDARD_PRINT
                        hw.data.hw_font_cursive = STANDARD_CURSIVE
                    })
            },
            INSERT_WORDS_AT(state, { getters, at, index, words }) {
                const documentItems = getters.documentItems
                const currentItem = documentItems[at]
                if (currentItem && currentItem.data && words.length) {
                    const currentWord = currentItem.data.words[index]
                    currentWord.value = words[0]
                    words.shift()
                    if (words.length > 0) {
                        currentItem.data.words.splice(
                            index + 1,
                            0,
                            ...words.map((w) => ({
                                partials: currentWord.partials,
                                value: w,
                            })),
                        )
                    }
                }
            },
            UPDATE_WORDS(state, { getters, content, at }) {
                const documentItems = getters.documentItems

                const currentItem = documentItems[at]

                const defaultWord = {
                    specific_filters: [],
                    partials: [],
                    blank: false,
                }

                if (currentItem && currentItem.data) {
                    const currentItemWords = currentItem.data.words
                    currentItem.data.words = content
                        .split(' ')
                        .filter((w) => w.trim)
                        .map((w, index) => {
                            const similiarWord = findSimiliarWord(w, currentItemWords, index)
                            return {
                                ...defaultWord,
                                ...similiarWord,
                                value: w,
                            }
                        })
                        .filter((w) => w.value)

                    currentItem.data.subtitle = currentItem.data.words
                        .map((w) => w.value)
                        .filter((w) => w)
                        .join(' ')
                }
            },
            UPDATE_MULTIPLE_CHOICES(state, { getters, at, choices }) {
                const documentItems = getters.documentItems
                const currentItem = documentItems[at]
                if (currentItem && currentItem.data) {
                    currentItem.data.choices = choices
                    const rightChoice = choices.find((c) => c.correct)?.answer
                    if (rightChoice) {
                        const blankWords = currentItem.data.words.filter((w) => w.blank)
                        const correctWords = rightChoice.split(', ')
                        // blankWords.forEach(
                        //     (word, index) => (word.value = correctWords[index])
                        // )
                    }
                }
            },
            RENUMBER_ITEMS(state, { getters }) {
                const documentItems = state.worksheet.body.items
                let number = 0
                documentItems.forEach((item, index) => {
                    if (!item.numberable || item.hide || item.numbering.hidden) return

                    if (item.numbering.numbering_restarted) {
                        number = 1
                        item.numbering.number = number
                        number = item?.type === 'word_scramble' ? item?.data.terms.length : number
                        return
                    }

                    if (item?.type === 'word_scramble') {
                        const wordScrambleItems = item?.data.terms.length
                        item.numbering.number = number + 1
                        number = wordScrambleItems + number
                        return
                    }

                    number++
                    item.numbering.number = number
                })
            },
            FORMAT_NUMBERS_AT(state, { at, format, getters }) {
                const documentItems = getters.documentItems
                const itemRange = []

                let index = at
                while (index >= 0) {
                    const item = documentItems[index]
                    if (item.numberable && item.numbering.numbering_restarted) {
                        itemRange.push(item)
                        break
                    }
                    if (item.numberable) itemRange.push(item)
                    index--
                }

                index = at + 1
                while (index < documentItems.length) {
                    const item = documentItems[index]
                    if (item.numberable && item.numbering.numbering_restarted) break
                    if (item.numberable) itemRange.push(item)

                    index++
                }

                itemRange.forEach((item) => {
                    item.numbering.format = format ? parseInt(format) : item.numbering.format
                })
            },
            TOGGLE_WORD_BLANK(state, { getters, at, index, partial }) {
                const documentItems = getters.documentItems
                const currentItem = documentItems[at]
                if (currentItem && currentItem.data) {
                    const word = currentItem.data.words[index]
                    if (!word?.partials?.length) {
                        word.partials = [partial]
                    } else {
                        const targetedPartial = word.partials.find((p) => p.start <= partial.start && p.end >= partial.end)
                        if (targetedPartial) {
                            word.partials.splice(word.partials.indexOf(targetedPartial), 1)
                            if (targetedPartial.start < partial.start) {
                                word.partials.push({
                                    start: targetedPartial.start,
                                    end: partial.start,
                                })
                            }
                            if (targetedPartial.end > partial.end) {
                                word.partials.push({
                                    end: targetedPartial.end,
                                    start: partial.end,
                                })
                            }
                        } else {
                            word.partials.push(partial)
                        }
                    }
                }
            },
            REMOVE_ITEM(state, index) {
                if (state.entity_type === 'worksheet') {
                    state.worksheet.body.items.splice(index, 1)
                } else {
                    state[state.entity_type].content.items.splice(index, 1)
                }
            },
            CLEAR_ITEMS(state) {
                if (state.entity_type === 'worksheet') {
                    state.worksheet.body.items = []
                } else {
                    state[state.entity_type].content.items = []
                    if (state.entity_type === 'bingo') state.callListPages = {}
                }
            },
            SWAP_ITEM(state, index) {
                const bodyType = state.entity_type === 'worksheet' ? 'body' : 'content'
                const t = state[state.entity_type][bodyType].items[index].term
                const d = state[state.entity_type][bodyType].items[index].definition
                const t_image = state[state.entity_type][bodyType].items[index]?.term_image ?? ''
                const d_image = state[state.entity_type][bodyType].items[index]?.definition_image ?? ''

                state[state.entity_type][bodyType].items[index].term = d
                state[state.entity_type][bodyType].items[index].definition = t
                state[state.entity_type][bodyType].items[index].term_image = d_image
                state[state.entity_type][bodyType].items[index].definition_image = t_image
            },
            EXCHANGE_ITEMS(state, { src, target, getters }) {
                getters.documentItems[src].exchangeOrder(getters.documentItems[target])
            },
            SET_STUDENT_FIELD(state, value) {
                state.student_fields.splice(value.index, 1, value.value)
            },
            PUSH_STUDENT_FIELD(state, field) {
                state.student_fields.push(field)
            },
            REMOVE_STUDENT_FIELD(state, index) {
                state.student_fields.splice(index, 1)
            },
            SET_GRADE_LEVELS(state, value) {
                state.grade_levels = value
            },
            SET_GRADE_LEVEL(state, value) {
                state.grade_levels.splice(value.index, 1, value.value)
            },
            PUSH_GRADE_LEVEL(state, field) {
                state.grade_levels.push(field)
            },
            REMOVE_GRADE_LEVEL(state, index) {
                state.grade_levels.splice(index, 1)
            },
            UPDATE_ITEM(state, record) {
                if (state.entity_type === 'worksheet') {
                    state.worksheet.body.items.splice(record.index, 1, record.item)
                } else {
                    state[state.entity_type].content.items.splice(record.index, 1, record.item)
                }
            },
            CHANGE_SHUFFLE_SEED(state) {
                if (state.entity_type === 'worksheet') {
                    state.worksheet.body.shuffle_seed = Math.floor(Math.random() * 9999)
                } else if (state.entity_type === 'bingo') {
                    state.bingo.shuffle_seed = Math.floor(Math.random() * 9999)
                }
            },
            SET_DOCUMENTS(state, value) {
                state.documents = value
            },
            SET_DOCUMENT_INSTRUCTION(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state[state.entity_type].instruction[value] = payloads[value]
                })
            },
            SET_DOCUMENT_INSTRUCTION_STYLE(state, payloads) {
                if (state[state.entity_type]?.instruction) {
                    Object.keys(payloads).forEach((value) => {
                        state[state.entity_type].instruction.style[value] = payloads[value]
                    })
                }
            },
            SET_DOCUMENT_PUBLISH_SETTINGS(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state[value] = payloads[value]
                })
            },
            REMOVE_DOCUMENT_UPDATE_INTERVAL(state) {
                if (state.updateInterval) {
                    clearInterval(state.updateInterval)
                    state.updateInterval = undefined
                }
            },
            CLEAR_DOCUMENT_MEDIA(state) {
                if (!state.media?.pdfs) return
                Object.keys(state.media?.pdfs).forEach((value) => {
                    state.media.pdfs[value] = ''
                })
            },
            SET_DOCUMENT_UPDATE_INTERVAL(state, value) {
                state.updateInterval = value
            },
            SET_HAS_MODIFICATION(state, value) {
                state.hasModification = value
            },
            SET_LAST_UPDATED(state, value) {
                state.last_saved_at = value
            },
            async ADD_NEW_WORDBANK(state, { getters, words, index }) {
                const wordbankIndex = getters.allWordbanks.length
                const items = state.worksheet.body.items
                const activity = items[index]
                const wordbank = new Activity('word_bank')
                const usedColors = getters.allWordbanks.map((w) => w.data.color)

                if (activity.data.wordbank_id) {
                    await this.dispatch('document/removeFromWordbank', {
                        wordbank_id: activity.data.wordbank_id,
                        index: index,
                    })
                }

                wordbank.data.setWordbankColor(wordbankIndex, usedColors)

                if (!activity.data.wordbank_id) {
                    if (words.length) {
                        wordbank.data.addWords(words, activity.id)
                    }
                }

                Vue.set(activity.data, 'wordbank_id', wordbank.data.id)
                Vue.set(activity.data, 'include_wordbank', true)

                const newWordbankIndex = setNewWordBankIndex(state.worksheet.body.items, index)

                state.worksheet.body.items.splice(newWordbankIndex, 0, wordbank)

                function setNewWordBankIndex(items, index) {
                    index++
                    while (items[index]?.type === 'word_bank') {
                        index++
                    }

                    return index
                }
            },
            UPDATE_PARTIAL_WORD_SPECIFIC_FILTERS(state, { getters, at, itemAt, letterIndex }) {
                const item = getters.documentItems[itemAt]
                const word = { ...item.data.words[at] }
                if (word?.specific_filters) {
                    let letterPos = word?.specific_filters.indexOf(letterIndex)

                    if (letterPos === -1) {
                        word.specific_filters = [...word.specific_filters, letterIndex]
                    } else {
                        word.specific_filters.splice(letterPos, 1)
                    }
                } else {
                    word.specific_filters = [letterIndex]
                }

                item.data.words.splice(at, 1, word)
            },
            UPDATE_PARTIAL_WORD(state, { getters, at, index, length, itemAt, exclude }) {
                const item = getters.documentItems[itemAt]
                const word = { ...item.data.words[at] }

                let partials = []
                // Remove intersecting partials
                for (let partial of word?.partials ? word.partials : []) {
                    if (index >= partial.end || index + length <= partial.start) {
                        partials.push(partial)
                    } else if (exclude) {
                        if (partial.end - partial.start > 2) {
                            partials.push({
                                start: partial.start,
                                end: index,
                            })
                            partials.push({
                                start: index + 1,
                                end: partial.end,
                            })
                        } else if (partial.end - partial.start === 2) {
                            if (index === partial.start) {
                                partials.push({
                                    start: partial.end - 1,
                                    end: partial.end,
                                })
                            } else {
                                partials.push({
                                    start: partial.start,
                                    end: partial.start + 1,
                                })
                            }
                        }
                    }
                }
                if (!exclude) {
                    partials.push({
                        start: index,
                        end: index + length,
                    })
                } else {
                    word.specific_filters = word.specific_filters.filter((f) => f !== index)
                }

                // Merge Duplicating Partials
                partials = partials.sort((a, b) => a.start - b.start)
                let newPartials = [],
                    last
                partials
                    .filter((p) => p.start !== p.end)
                    .forEach(function (r) {
                        if (!last || r.start > last.end) newPartials.push((last = r))
                        else if (r.end > last.end) last.end = r.end
                    })
                word.partials = newPartials
                word.blank = true

                item.data.words.splice(at, 1, word)
            },
            REMOVE_FROM_WORDBANK(state, { getters, wordbank_id, index }) {
                const activity = getters.documentItems[index]
                const wordbank = getters.allWordbanks.find((w) => w.data.id === wordbank_id)
                let wordbankIndex = -1

                Vue.set(activity.data, 'wordbank_id', undefined)

                if (!wordbank) return

                if (activity) {
                    wordbank.data.removeAll(activity.id)
                }

                if (wordbank.data.words.length) return

                getters.documentItems.forEach((item, index) => {
                    if (item.id === wordbank.id) wordbankIndex = index

                    if (item.data.wordbank_id === wordbank.id) {
                        Vue.set(item.data, 'wordbank_id', undefined)
                    }
                })

                state.worksheet.body.items.splice(wordbankIndex, 1)
            },
            UPDATE_WORDBANK(state, { getters, words, index, target_id }) {
                const items = state.worksheet.body.items
                const activity = items[index]
                let wordbank = findWordbank(items, activity, target_id)

                if (!wordbank) {
                    wordbank = createNewWordbank(getters, items, index, target_id)
                }

                updateWordbankContent(wordbank, activity, words, target_id)

                if (!activity) return

                if (!wordbank.data.words.length) {
                    this.dispatch('document/removeFromWordbank', {
                        wordbank_id: wordbank.data.id,
                        index: index,
                    })
                }

                Vue.set(activity.data, 'wordbank_id', wordbank.data.id)

                function findWordbank(items, activity, target_id) {
                    if (target_id) {
                        return items.find((i) => i.type === 'word_bank' && i.data.id === target_id)
                    }
                    return items.find((i) => i.type === 'word_bank' && i.data.id === activity.data.wordbank_id)
                }

                function createNewWordbank(getters, target_id) {
                    const wordbank = new Activity('word_bank')
                    const wordbankIndex = getters.allWordbanks.length
                    const usedColors = getters.allWordbanks.map((w) => w.data.color)

                    wordbank.data.id = target_id || wordbank.id

                    wordbank.data.setWordbankColor(wordbankIndex, usedColors)
                    return wordbank
                }

                function updateWordbankContent(wordbank, activity, words, target_id) {
                    wordbank.data.removeAll(activity.id)
                    if (target_id === wordbank.data.id && words.length) {
                        wordbank.data.addWords(words, activity.id)
                        sortWords(wordbank)
                    }
                }

                function sortWords(wordbank) {
                    if (!wordbank) return

                    if (wordbank.data.sort_order === 'a-z') {
                        wordbank.data.words.sort((wi1, wi2) =>
                            !wi1.word ? 1 : !wi2.word ? -1 : wi1.word.localeCompare(wi2.word),
                        )
                        return
                    }

                    wordbank.data.words.sort(() => 0.5 - Math.random())
                }
            },
            USER_HAS_PURCHASED_DOCUMENT(state, value) {
                state.user_has_purchased_document = value
            },
            SET_FLASHCARD_HEADER_HEIGHT(state, payload) {
                state.flashcardHeaderHeight = payload
            },
            RESET_DOCUMENT_HEADER(state) {
                state.title = ''
                state.title_color = default_color
                state.title_font = default_font
                state.title_font_size = 32
                state.title_visible = 1
                state.student_info_color = default_color
                state.student_info_font = default_font
                state.student_info_font_size = 18.6664
                state.student_info_visible = 1
            },
            SET_PAGE_COUNT(state, count) {
                state.page_count = count
            },
            SET_SINGLE_PURCHASE_EDITS(state, value) {
                state.single_purchase_edits = value
            },
            SET_GO_PREMIUM(state, value) {
                state.goPremium = value
            },
            SET_SHOW_IMAGE_UPLOADER(state, value) {
                state.show_image_uploader = value
            },
            SET_IS_INLINE_IMAGE(state, value) {
                state.is_inline_image = value
            },
            SET_INLINE_IMAGES_UPLOAD(state, payload) {
                Object.keys(payload).forEach((value) => {
                    state.inline_images_upload[value] = payload[value]
                })
            },
            CLEAR_INLINE_IMAGES(state) {
                state[state.entity_type].inline_images = []
            },
        }
    }

    getModules() {
        const zoomModule = new ZoomModule().getModules()
        const premiumModule = new PremiumModule().getModules()
        const worksheetModule = new WorksheetModule().getModules()
        const bingoModule = new BingoModule().getModules()
        const flashcardModule = new FlashcardModule().getModules()
        const imageModule = new ImageModule().getModules()
        return mergeDeep(
            super.getModules(),
            worksheetModule,
            bingoModule,
            flashcardModule,
            zoomModule,
            premiumModule,
            imageModule,
        )
    }
}
