<template>
    <div class="min-width">
        <label class="font-weight-bold mb-1 f-11 text-muted text-nowrap" :class="{ 'text-disabled': disabled }">
            {{ label }}
        </label>
        <b-form-input v-model="color" type="color" size="sm" :disabled="disabled" @change="$emit('change')" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ColorPicker',
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        color: {
            get() {
                return this.modelValue
            },
            set(value: string) {
                this.$emit('input', value)
                this.$emit('change', value)
            },
        },
    },
})
</script>

<style scoped>
.min-width {
    min-width: 60px;
    width: 60px;
}
</style>
