<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <div id="customToolbar" class="ql-toolbar">
                <div class="d-flex justify-content-between mb-0">
                    <div v-click-outside="onClickOutside" class="d-flex p-2 w-100">
                        <div class="d-flex">
                            <div
                                class="f-11 text-muted border p-0 w-100 h-100 rounded ql-formats cursor-pointer mr-3"
                                tabindex="-1"
                                :class="{
                                    'border-primary-1': styleSelector === HANDWRITING_TYPE_SOLID,
                                }"
                                @click.prevent="setLineStyle(HANDWRITING_TYPE_SOLID)"
                            >
                                <img
                                    :src="'/images/worksheets/handwriting/solid.png'"
                                    class="handwriting rounded border-0"
                                />
                            </div>
                        </div>
                        <div class="d-flex">
                            <div
                                class="f-11 text-muted border p-0 w-100 h-100 rounded ql-formats cursor-pointer mr-3"
                                tabindex="-1"
                                :class="{
                                    'border-primary-1': styleSelector === HANDWRITING_TYPE_TRACE,
                                }"
                                @click.prevent="setLineStyle(HANDWRITING_TYPE_TRACE)"
                            >
                                <img
                                    v-if="defaultFontSelected"
                                    :src="'/images/worksheets/handwriting/trace.png'"
                                    class="handwriting rounded border-0"
                                />
                                <img
                                    v-else
                                    :src="'/images/worksheets/handwriting/outline.png'"
                                    class="handwriting rounded border-0"
                                />
                            </div>
                        </div>

                        <div v-if="defaultFontSelected" class="d-flex">
                            <div
                                class="f-11 text-muted border p-0 w-100 h-100 rounded ql-formats cursor-pointer"
                                tabindex="-1"
                                :class="{
                                    'border-primary-1': styleSelector === HANDWRITING_TYPE_STROCK,
                                }"
                                @click.prevent="setLineStyle(HANDWRITING_TYPE_STROCK)"
                            >
                                <img
                                    :src="'/images/worksheets/handwriting/stroke.png'"
                                    class="handwriting rounded border-0"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-center mr-2" style="z-index: 0">
                        <b-form-radio v-model="itemData.cursive_style" name="some-radios" value="print" @change="updateData">
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">Print</div>
                        </b-form-radio>
                        <b-form-radio
                            v-model="itemData.cursive_style"
                            name="some-radios"
                            value="cursive"
                            @change="updateData"
                        >
                            <div class="text-muted f-11" style="height: 1.5rem; line-height: 1.5rem">Cursive</div>
                        </b-form-radio>
                    </div>
                </div>
            </div>
            <VueEditor
                :ref="`widget_${index}`"
                v-model="handwritingInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Letters or words to copy (optional)"
                @text-change="onChangeHandwritingText"
                @focus="setCursorOnFocus"
                @blur="focused = false"
            ></VueEditor>
        </div>

        <AdvancedOptionsToggleButton :active="showAdvancedOptions" @click="showAdvancedOptions = !showAdvancedOptions" />

        <div v-if="showAdvancedOptions" class="mt-2">
            <div class="d-flex p-0 d-flex flex-column">
                <label class="font-weight-bold mb-1 f-11 text-muted">
                    {{ itemData.cursive_style.charAt(0).toUpperCase() + itemData.cursive_style.slice(1) }}
                    Font Type
                </label>
                <div :id="uniqueId">
                    <FontSelect
                        id="hw-font"
                        :key="'hw-font'"
                        class="mt-0 mb-0 pb-0 w-100"
                        :font="currentFont"
                        :options="false"
                        :fonts="hwFonts"
                        :disabled="hasStroked"
                        size="sm"
                        @changed="onChangeFont"
                    />
                    <b-popover v-if="hasStroked" :target="uniqueId" triggers="hover" placement="top">
                        <div class="text-center">
                            Only the “Standard Print” and “Standard Cursive” fonts support stroked text. Use another guide
                            style to choose a different font.
                        </div>
                    </b-popover>
                </div>
            </div>
            <div class="d-flex align-items-baseline justify-content-between">
                <div class="w-75 mr-4">
                    <div class="d-flex justify-content-between align-items-end mt-2">
                        <label class="font-weight-bold mb-0 f-11 text-muted">Line Height</label>
                        <span class="f-10 text-muted">
                            {{ itemData.line_height }}
                        </span>
                    </div>
                    <div>
                        <b-form-input
                            v-model="itemData.line_height"
                            type="range"
                            min="30"
                            max="50"
                            @input="updateData"
                        ></b-form-input>
                    </div>
                </div>

                <div>
                    <ColorPicker v-model="itemData.guide_line_color" label="Color" @change="updateData" />
                </div>
            </div>

            <div>
                <div class="d-flex justify-content-between align-items-end mt-2">
                    <label class="font-weight-bold mb-0 f-11 text-muted">Number of Lines</label>
                    <span class="f-10 text-muted">
                        {{ itemData.number_of_lines }}
                    </span>
                </div>
                <b-form-input
                    v-model="itemData.number_of_lines"
                    type="range"
                    min="1"
                    max="10"
                    @input="updateData"
                ></b-form-input>
            </div>
        </div>
        <div style="visibility: hidden">
            <span style="font-family: 'Cursive Stroked'"></span>
            <span style="font-family: 'Cursive Dashed'"></span>
            <span style="font-family: 'Cursive Regular'"></span>
            <span style="font-family: 'Precursive Stroked'"></span>
            <span style="font-family: 'Precursive Regular'"></span>
            <span style="font-family: 'Precursive Dashed'"></span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import uniqueId from 'lodash.uniqueid'
import { VueEditor } from 'vue2-editor'
import {
    HANDWRITING_TYPE_SOLID,
    HANDWRITING_TYPE_STROCK,
    HANDWRITING_TYPE_TRACE,
    STANDARD_CURSIVE,
    STANDARD_PRINT,
    TYPE_CURSIVE,
    TYPE_PRINT,
} from '../../store/helpers/documentHelpers'
import FontSelect from '../../widgets/font-select.vue'
import ColorPicker from '../ColorPicker.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import DocumentHelper from '../../mixins/DocumentHelper'

export default defineComponent({
    name: 'Handwriting',
    components: {
        ColorPicker,
        AdvancedOptionsToggleButton,
        VueEditor,
        FontSelect,
    },
    mixins: [DocumentHelper],
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showAdvancedOptions: false,
            customToolbar: [[], [], []],
            editorSettings: {
                formats: ['script'],
                modules: {
                    toolbar: {
                        container: '#customToolbar',
                    },
                },
            },
            focused: false,
            itemData: {
                ...this.data,
            },
            HANDWRITING_TYPE_SOLID,
            HANDWRITING_TYPE_TRACE,
            HANDWRITING_TYPE_STROCK,
            uniqueId: null,
        }
    },
    computed: {
        handwritingInput: {
            get() {
                return this.itemData.subtitle
            },
            set(value) {
                this.itemData.subtitle = value.replace(/(<([^>]+)>)/gi, '').trim()
            },
        },
        hasMultipleStyle() {
            const styles = [...new Set(this.itemData.partials.map((item) => item.style))]
            return styles > 1
        },
        currentFont() {
            return this.itemData[`hw_font_${this.itemData.cursive_style}`]
        },
        hwFonts() {
            if (this.itemData.cursive_style === TYPE_CURSIVE) {
                return this.$fonts.hwCursiveFonts
            } else {
                return this.$fonts.hwPrintFonts
            }
        },
        selectedStyle() {
            let quillRef = this.$refs[`widget_${this.index}`]?.quill
            let editorSelection = quillRef?.selection

            if (this.hasSelection()) {
                const savedRange = editorSelection.savedRange
                const partial = this.itemData.partials.find(
                    (p) => p.start <= savedRange.index && savedRange.index + savedRange.length <= p.end,
                )

                if (partial) {
                    return partial.style
                }
            }

            return ''
        },
        hasStroked() {
            return (this.itemData.partials?.filter((p) => p.style === HANDWRITING_TYPE_STROCK).length || 0) > 0
        },
        partialStyles() {
            let styles = []
            this.itemData.partials.forEach((p) => {
                if (!styles.includes(p.style)) {
                    styles.push(p.style)
                }
            })
            return styles
        },
        styleSelector() {
            if (!this.focused && this.partialStyles.length === 1) {
                return this.partialStyles[0]
            } else if (this.focused && this.hasSelection()) {
                return this.selectedStyle
            } else if (this.focused) {
                return this.itemData.line_style
            }

            return ''
        },
        defaultFontSelected() {
            return (
                (this.itemData.cursive_style === TYPE_CURSIVE && this.itemData.hw_font_cursive === STANDARD_CURSIVE) ||
                (this.itemData.cursive_style === TYPE_PRINT && this.itemData.hw_font_print === STANDARD_PRINT)
            )
        },
        cursiveFont() {
            return this.item.data[`hw_font_${this.item.data.cursive_style}`]
        },
    },
    created() {
        this.uniqueId = uniqueId('handwriting_')
    },
    mounted() {
        this.setCursorOnFocus()
    },
    methods: {
        getAfterPartials(partial) {
            const partialIndex = this.itemData.partials.indexOf(partial)
            let afterPartials = []
            if (partialIndex !== -1 && partialIndex !== this.itemData.partials.length - 1) {
                afterPartials = this.itemData.partials.slice(partialIndex + 1)
            }

            return afterPartials
        },
        hasSelection() {
            let quillRef = this.$refs[`widget_${this.index}`]?.quill
            let editorSelection = quillRef?.selection

            return editorSelection && editorSelection.savedRange.index !== undefined && editorSelection.savedRange.length
        },
        initPartials() {
            if (!this.itemData.partials) {
                this.itemData.partials = []
            }
        },
        updateData() {
            this.$emit('change', this.itemData)
        },
        setTraceStyleForRange({ start, end, style, retain, insert }) {
            if (!this.itemData.partials) return

            if (insert) {
                this.itemData.partials.forEach((p) => {
                    if (p.start > retain) {
                        p.start += insert
                    }
                    if (p.end > retain) {
                        p.end += insert
                    }
                })
                this.sortPartials()
            }

            this.itemData.partials = this.itemData.partials.flatMap((p) => {
                if (p.end <= start || end <= p.start) {
                    return [p]
                } else if (p.start <= start && p.end <= end && start != p.end) {
                    return p.start === start
                        ? [{ start, end, style }]
                        : [
                              { start: p.start, end: start, style: p.style },
                              { start, end, style },
                          ]
                } else if (start <= p.start && end <= p.end) {
                    return [
                        { start, end, style },
                        { start: end, end: p.end, style: p.style },
                    ]
                } else if (p.start < start && end < p.end) {
                    return [
                        { start: p.start, end: start, style: p.style },
                        { start, end, style },
                        { start: end, end: p.end, style: p.style },
                    ]
                }

                return []
            })

            this.removeDuplications()
        },
        removeDuplications() {
            let newPartials = [],
                last
            this.itemData.partials.forEach(function (r) {
                if (!last || r.start > last.end || r.style !== last.style) newPartials.push((last = r))
                else if (r.end > last.end) {
                    last.end = r.end
                    last.style = r.style
                } else if (r.start < last.end) {
                    r.start = last.end
                }
            })

            this.itemData.partials = newPartials
        },
        setCursorOnFocus() {
            this.$nextTick(() => {
                this.setCursorPositionOnFocus(`widget_${this.index}`, this.handwritingInput.length + 1)
                this.focused = true
            })
        },
        setLineStyle(style) {
            let quillRef = this.$refs[`widget_${this.index}`]?.quill
            let editorSelection = quillRef?.selection
            if (quillRef && this.focused) {
                quillRef.focus()
            }

            if (editorSelection?.savedRange.index !== undefined && editorSelection?.savedRange.length) {
                const range = editorSelection.savedRange
                this.setTraceStyleForRange({
                    start: range.index,
                    end: range.index + range.length,
                    style,
                })
            } else if (!this.focused) {
                this.itemData.partials = [
                    {
                        start: 0,
                        end: this.itemData.subtitle.length,
                        style,
                    },
                ]
            }

            this.itemData.line_style = style
            this.updateData()
        },
        onChangeHandwritingText({ ops }) {
            if (
                ops.length === 1 &&
                ops[0].insert === this.itemData.subtitle &&
                this.itemData.subtitle.length > 1 &&
                this.itemData.partials.length
            ) {
                return
            }
            if ((ops.length === 1 && ops[0].insert) || (ops.length === 1 && ops[0].delete)) {
                ops.unshift({ retain: 0 })
            }

            if (ops.length > 1 && this.itemData.partials) {
                const [{ retain }, op] = ops
                this.focused = true
                if (op.insert) {
                    const targetPartial = this.itemData.partials.find(
                        (p) => p.start <= retain && retain <= p.end && p.style === this.itemData.line_style,
                    )

                    const insertCount = op.insert.length
                    if (targetPartial && insertCount) {
                        this.updateAfterPartials(targetPartial, insertCount)
                        targetPartial.end += insertCount
                    } else {
                        const partial = this.itemData.partials.find((p) => p.start <= retain && retain < p.end)
                        if (partial) {
                            this.setTraceStyleForRange({
                                start: retain,
                                end: retain + insertCount,
                                style: partial.style,
                                insert: insertCount,
                                retain,
                            })
                        } else {
                            this.itemData.partials.push({
                                start: retain,
                                end: retain + insertCount,
                                style: this.itemData.line_style,
                            })
                            this.sortPartials()
                        }
                    }
                } else if (op.delete) {
                    this.onDeleteText({
                        retain,
                        deleteCount: op.delete,
                    })
                }

                this.removeDuplications()
                this.updateData()
            }
        },
        onDeleteText({ retain, deleteCount }) {
            const targetPartial = this.itemData.partials.find((p) => p.start <= retain && retain < p.end)

            if (targetPartial && deleteCount) {
                let deletedCount = 0
                deletedCount = Math.min(targetPartial.end - retain, deleteCount)
                targetPartial.end -= deletedCount
                this.updateAfterPartials(targetPartial, -deletedCount)

                if (targetPartial.end <= targetPartial.start) {
                    this.removePartial(targetPartial)
                }
                deleteCount -= deletedCount
                if (deleteCount > 0) {
                    this.onDeleteText({
                        retain: retain + deletedCount,
                        deleteCount,
                    })
                }
            }
        },
        removePartial(partial) {
            const rmIndex = this.itemData.partials.findIndex((p) => p.start === partial.start && p.end === partial.end)
            this.itemData.partials.splice(rmIndex, 1)
        },
        onChangeFont({ font }) {
            this.itemData[`hw_font_${this.itemData.cursive_style}`] = font
            this.updateData()
        },
        onClickOutside(e) {
            const isQuillEditor = e?.srcElement?.parentElement?.className === 'ql-editor'
            if (isQuillEditor) {
                let quillRef = this.$refs[`widget_${this.index}`]?.quill
                quillRef?.focus()
            }
            this.focused = isQuillEditor
        },
        sortPartials() {
            this.itemData.partials.sort((p1, p2) => p1.start - p2.start)
        },
        updateAfterPartials(partial, count) {
            if (!partial || !count) return

            this.getAfterPartials(partial).forEach((p) => {
                p.start += count
                p.end += count
            })
            this.sortPartials()
        },
    },
})
</script>
