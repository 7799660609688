<template>
    <div>
        <div class="card-body py-1">
            <div>
                <MultiOptions
                    v-model="localData.terms"
                    is-list-items
                    :options="[]"
                    :parent-index="index"
                    variant="word-scramble"
                    focus
                    :has-sub-options="localData.type === WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM"
                    :sub-options-choices="localData.terms_choices"
                    @input="updateTerms"
                    @scramble="scrambleWord"
                    @subOptions="updateSubOptions"
                />
                <div class="d-flex mt-2 justify-content-between">
                    <div v-if="showScrambleByWord" class="d-flex flex-column">
                        <label class="font-weight-bold mb-0 f-11 text-muted">Scramble by word</label>
                        <b-form-checkbox
                            v-model="localData.scramble_by_word"
                            class="f-13"
                            name="check-button"
                            :value="1"
                            :unchecked-value="0"
                            switch
                            @change="updateData"
                        />
                    </div>
                    <div v-else />

                    <IncludeWordbank :index="index" @updateData="updateWordBankData" />
                </div>

                <AdvancedOptionsToggleButton
                    :active="showAdvancedOptions"
                    @click="showAdvancedOptions = !showAdvancedOptions"
                />

                <div v-if="showAdvancedOptions" class="mt-2">
                    <WordScrambleSettings v-model="settings" />
                    <WordScrambleLayout v-model="layoutOptions" />
                    <BorderSettings v-model="borderStyle" class="mt-2" collapsible />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import MultiOptions from '../MultiOptions.vue'
import { mapGetters } from 'vuex'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import { AnswerChoice, WordScrambleData, WordScrambleStyle } from '../../types/word-scramble'
import WordScrambleSettings from '../format-helpers/WordScrambleSettings.vue'
import WordScrambleLayout from '../format-helpers/WordScrambleLayout.vue'
import BorderSettings from '../../widgets/BorderSettings.vue'
import { BorderSettingsOptions } from '../../objects/BorderSettingsOption'
import { WORD_SCRAMBLE_TYPE_MULTIPLE, WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM } from '../../store/helpers/documentHelpers'
import IncludeWordbank from '../IncludeWordbank.vue'
import { answerOptions } from '../../objects/AnswerOptions'

interface Data {
    content: string
    showAdvancedOptions: boolean
    customToolbar: string[][]
    localStyle: WordScrambleStyle
    WORD_SCRAMBLE_TYPE_MULTIPLE: number
    WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM: number
    answerOptions: { value: number; text: string }[]
}

export default defineComponent({
    name: 'WordScramble',
    components: {
        IncludeWordbank,
        AdvancedOptionsToggleButton,
        BorderSettings,
        MultiOptions,
        WordScrambleLayout,
        WordScrambleSettings,
    },
    props: {
        data: {
            type: Object as PropType<WordScrambleData>,
            required: true,
        },
        index: {
            type: Number,
            default: -1,
        },
        itemStyle: {
            type: Object as PropType<WordScrambleStyle>,
            required: true,
        },
    },
    data(): Data {
        return {
            content: '',
            showAdvancedOptions: false,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            localStyle: { ...this.itemStyle },
            WORD_SCRAMBLE_TYPE_MULTIPLE,
            WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM,
            answerOptions,
        }
    },
    computed: {
        ...mapGetters({
            allWordbanks: 'document/allWordbanks',
            currentWidget: 'document/currentWidget',
        }),
        localData: {
            get() {
                return this.data
            },
            set(value: WordScrambleData) {
                this.localData = value
            },
        },
        settings: {
            get(): WordScrambleData {
                return { ...this.localData, ...this.data }
            },
            set(value: WordScrambleData) {
                if (
                    this.localData.type === WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM &&
                    value.type !== WORD_SCRAMBLE_TYPE_MULTIPLE_PER_TERM
                ) {
                    this.localData.terms_choices = []
                }

                this.localData.type = value.type
                this.localData.text_case = value.text_case
                this.updateData()
            },
        },
        layoutOptions: {
            get(): WordScrambleData {
                return { ...this.localData, ...this.data }
            },
            set(value: WordScrambleData) {
                this.localData.number_of_columns = value.number_of_columns
                this.localData.order_numbers_by = value.order_numbers_by
                this.localData.line_position = value.line_position
                this.localData.line_length = value.line_length

                this.updateData()
            },
        },
        borderStyle: {
            get(): BorderSettingsOptions {
                return { ...this.localStyle, ...this.itemStyle } as BorderSettingsOptions
            },
            async set(value: WordScrambleStyle) {
                this.localStyle.border_width = value.border_width
                this.localStyle.border_style = value.border_style
                this.localStyle.border_color = value.border_color

                await this.$store.dispatch('document/updateWidgetStyles', { ...this.localStyle, ...{ index: this.index } })
            },
        },
        showScrambleByWord(): number {
            if (!this.localData.terms?.length) return 0
            return this.localData.terms?.some((term) => term && term.trim().split(' ').length > 1) ? 1 : 0
        },
    },
    watch: {
        allWordbanks: {
            deep: true,
            handler() {
                if (this.allWordbanks?.length) return

                this.$nextTick(() => {
                    this.localData.include_wordbank = false
                    this.localData.wordbank_id = undefined
                })
            },
        },
    },
    async mounted() {
        this.updateData()
    },
    methods: {
        updateData() {
            this.$emit('change', this.localData)
        },
        scrambleWord(index: number) {
            if (!this.localData.shuffle_seeds) return

            this.localData.shuffle_seeds[index] = Math.floor(Math.random() * 9999)
            this.updateData()
        },
        async updateTerms(value: string[]) {
            await this.$store.dispatch('document/updateWordScrambleTerms', {
                index: this.index,
                terms: value,
            })
            this.updateData()
        },
        updateSubOptions(values: AnswerChoice[]) {
            this.localData.terms_choices = values
            this.updateData()
        },
        updateWordBankData(value: WordScrambleData) {
            this.$emit('change', value)
        },
    },
})
</script>
