<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <VueEditor
                :ref="`widget_${index}`"
                v-model="instructionInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Write your instructions here."
                @focus="setCursorOnFocus()"
                @text-change="updateData"
            ></VueEditor>
        </div>

        <BorderSettings v-model="itemStyleData" class="mt-3" collapsible @input="updateData" />

        <!-- TODO: Not sure if we can/should delete this -->
        <div style="display: none">
            <label class="font-weight-bold mb-1 f-11 text-muted">Choose Instruction Template</label>
            <b-form-select
                v-model="selected"
                label="Body Font"
                :options="options"
                size="sm"
                class="mt-0"
                @change="populateInstruction"
            ></b-form-select>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import BorderSettings from '../../widgets/BorderSettings.vue'
import DocumentHelper from '../../mixins/DocumentHelper'

export default defineComponent({
    name: 'InstructionBox',
    components: {
        VueEditor,
        BorderSettings,
    },
    mixins: [DocumentHelper],
    props: {
        data: {
            type: Object,
            required: true,
        },
        itemStyle: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            itemStyleData: {
                ...this.itemStyle,
            },
            selected: 0,
            options: [
                { value: 0, text: 'Custom Instructions' },
                { value: 1, text: 'Open Response Instructions' },
                { value: 2, text: 'Multiple Choice Instructions' },
                { value: 3, text: 'Handwriting Instructions' },
                { value: 4, text: 'Matching Instructions' },
                { value: 5, text: 'Fill in the Blank Instructions' },
                { value: 6, text: 'Word Scramble Instructions' },
            ],
            instructions: [
                '',
                'Answer each question in the space provided.',
                'Circle the correct answer for each question below.',
                'Use your best handwriting to write each word below.',
                'Match the word on the left side to its definition on the right side.',
                'Fill in the blank with the missing word.',
                'Unscramble each word and write it in the blank provided.',
            ],
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        instructionInput: {
            get() {
                return this.itemData.instruction
            },
            set(value) {
                this.itemData.instruction = value
            },
        },
    },
    mounted() {
        this.setCursorOnFocus()
    },
    methods: {
        populateInstruction(value) {
            this.itemData.instruction = this.instructions[value]
            this.updateData()
        },
        updateData() {
            this.$emit('change', this.itemData)
            this.$emit('update:itemStyle', this.itemStyleData)
        },
        setCursorOnFocus() {
            this.$nextTick(() => {
                this.setCursorPositionOnFocus(`widget_${this.index}`, this.instructionInput.length)
            })
        },
    },
})
</script>
