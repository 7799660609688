<template>
    <div class="mb-1">
        <div v-if="collapsible" class="border-action w-100 d-flex justify-content-center align-items-center">
            <div class="line"></div>
            <button
                :class="isOpen ? 'btn-primary' : 'btn-outline-primary'"
                class="btn rounded btn-sm text-uppercase"
                aria-label="Toggle Border Settings"
                @click="toggleSettings"
            >
                <span>Border</span>
                <IconPalette class="icon-palette" />
                <b-icon icon="chevron-down" />
            </button>
            <div class="line"></div>
        </div>
        <div v-show="isOpen" class="border-settings">
            <div class="row">
                <div class="col-md-2">
                    <label class="font-weight-bold mb-0 f-11 text-muted">Border</label>
                    <b-form-checkbox v-model="hasBorder" class="f-14" name="has_border" switch @change="handleInput" />
                </div>
                <div class="col-md-7">
                    <label class="font-weight-bold mb-1 f-11 text-muted" :class="{ 'text-disabled': !hasBorder }">
                        Thickness
                    </label>
                    <b-form-input
                        v-model.number="border.border_width"
                        type="range"
                        :min="1"
                        :max="8"
                        :disabled="!hasBorder"
                        @change="handleInput"
                    ></b-form-input>
                </div>

                <div class="col-md-3 d-flex justify-content-end">
                    <ColorPicker v-model="border.border_color" label="Color" :disabled="!hasBorder" @change="handleInput" />
                </div>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted mt-2" :class="{ 'text-disabled': !hasBorder }">
                    Style
                </label>
                <b-form-radio-group
                    id="border-style"
                    v-model="border.border_style"
                    name="border-style"
                    :disabled="!hasBorder"
                >
                    <div class="d-flex row">
                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="solid" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label solid" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Solid</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="dashed" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label dashed" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Dashed</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="dotted" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label dotted" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Dotted</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="double" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label double" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Double</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-2 row">
                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="inset" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label inset" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Inset</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="outset" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label outset" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Outset</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="ridge" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label ridge" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Ridge</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="groove" :disabled="!hasBorder" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column border-line-label groove" :class="{ disabled: !hasBorder }">
                                <span class="f-11 text-muted text-height-1">Groove</span>
                            </div>
                        </div>
                    </div>
                </b-form-radio-group>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BorderSettingsOptions } from '../objects/BorderSettingsOption'
import ColorPicker from '../components/ColorPicker.vue'
import IconPalette from '../stories/components/icons/IconPalette.vue'

export default defineComponent({
    name: 'BorderSettings',
    components: {
        ColorPicker,
        IconPalette,
    },
    props: {
        value: {
            type: Object as PropType<BorderSettingsOptions>,
            default: undefined,
        },
        collapsible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            border: this.value,
            isOpen: !this.collapsible,
            hasBorder: !!this.value?.border_width,
        }
    },
    watch: {
        hasBorder: {
            handler(value: boolean) {
                if (!value) {
                    this.border.border_width = 0
                    this.handleInput()
                    return
                }

                if (!this.border.border_width) {
                    this.border.border_width = 2
                    this.handleInput()
                }
            },
            immediate: true,
        },
    },
    methods: {
        handleInput() {
            this.$emit('input', this.border)
        },
        toggleSettings() {
            this.isOpen = !this.isOpen
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.border-settings {
    .custom-control-inline {
        margin-right: 0;
    }
}
.line {
    flex-grow: 1;
    border-top: 1px dashed $gray-500;
}

.icon-palette {
    margin-right: 0.75rem;
    margin-left: 0.3125rem;
}

.border-line-label {
    border-color: $black;
    span {
        color: $gray-900;
    }

    &.disabled {
        border-color: $gray-600;
        span {
            color: $gray-600;
        }
    }
}
</style>
